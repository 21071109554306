// database.js
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { createClient } from '@supabase/supabase-js';
import { useState, useEffect } from 'react';

const SUPABASE_URL = 'https://rloecptpbbqcujnaftmf.supabase.co';
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJsb2VjcHRwYmJxY3VqbmFmdG1mIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDc5OTUxMTQsImV4cCI6MjAyMzU3MTExNH0.-F_O19qUBVD6F0JAh1XhLj1i48jJQWyhJcdKVYMp86M';
const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

const toCamelCase = (str) => str.replace(/([-_][a-z])/g, (group) =>
  group.toUpperCase()
    .replace('-', '')
    .replace('_', '')
);

const convertKeysToCamelCase = (input) => {
  if (typeof input !== 'object' || input === null) {
    return input;
  } else if (Array.isArray(input)) {
    return input.map(convertKeysToCamelCase);
  }

  return Object.entries(input).reduce((newObj, [key, value]) => {
    const newValue = typeof value === 'object' && value !== null ? convertKeysToCamelCase(value) : value;
    newObj[toCamelCase(key)] = newValue;
    return newObj;
  }, {});
};


export const useGetUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const { data, error } = await supabase.rpc('get_users_in_company');

      if (error) {
        throw new Error(error.message);
      }
      // Convert all keys in the data from snake_case to camelCase
      const camelCaseData = convertKeysToCamelCase(data);

      return camelCaseData;
    },
    enabled: true, // Enable the query by default
  });
};

export const useGetUser = (uuid) => {
  return useQuery({
    queryKey: ['user', uuid],
    queryFn: async () => {
      // Ensure UUID is present before making any calls
      if (!uuid) {
        return Promise.reject(new Error("UUID is undefined"));
      }

      try {
        const response = await supabase.from('users').select('*').eq('uuid', uuid).single();
        const userData = response.data;

        if (userData) {
          return userData;
        } else {
          return Promise.reject(new Error('No user data found'));
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
    enabled: !!uuid,
  });
};
// if we dont need modifiers
export const getUser = async (userUuid) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('uuid', userUuid)
      .single(); // Assuming 'uuid' is the unique identifier for users

    if (error) throw error;

    return data; // Returns user data
  } catch (error) {
    console.error('Error fetching user:', error.message);
    return null; // Handle the error appropriately
  }
};


// Fetch single process
export const useGetProcess = (processID) => {
  return useQuery({
    queryKey: ['process', processID],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('processes')
        .select('*')
        .eq('id', processID)
        .single();
      if (error) throw new Error(error.message);
      return data;
    },
    enabled: !!processID,
  });
};

// Fetch all processes
export const useGetProcesses = () => {
  return useQuery({
    queryKey: ['process'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('processes')
        .select('*')
        .order('id', { ascending: true });
      if (error) throw new Error(error.message);
      return data;
    }
  });
};

export const useUpdateProcess = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ processId, update }) => {
      const { error } = await supabase
        .from('processes')
        .update(update) // Pass the updates object directly
        .match({ id: processId });

      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['processes']);
    },
    onError: (error) => {
      console.error('Error updating process:', error.message);
    },
  });
};


// Fetch tasks for a process
export const useGetTasks = (processID) => {
  return useQuery({
    queryKey: ['tasks', processID],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('tasks')
        .select('*')
        .eq('processId', processID);
      if (error) throw new Error(error.message);
      return data;
    },
    enabled: !!processID,
  });
};

// Fetch tasks by an array of task IDs
export const useGetTasksByIds = (taskIds) => {
  return useQuery({
    queryKey: ['tasksByIds', taskIds],
    queryFn: async () => {
      if (taskIds.length === 0) {
        return []; // Return an empty array if no taskIds are provided
      }

      const { data, error } = await supabase
        .from('tasks')
        .select('*')
        .in('id', taskIds);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
    enabled: taskIds.length > 0,
  });
};


// Update task user assignment
export const useUpdateTaskUserAssignment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ taskId, usersAssigned }) => {
      const { error } = await supabase
        .from('tasks')
        .update({ usersAssigned: usersAssigned })
        .match({ id: taskId });
      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
};

// export const useUpdateTaskMilestone = () => {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: async ({ taskId, milestone }) => {
//       const { error } = await supabase
//         .from('tasks')
//         .update({ milestone })
//         .match({ id: taskId });

//       if (error) throw new Error(error.message);
//     },
//     onSuccess: () => {
//       // Invalidate tasks query to refresh data
//       queryClient.invalidateQueries(['tasks']);
//     },
//   });
// };

export const useUpdateTaskMilestone = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ taskId, attribName, attribValue }) => {
      let updateObj = {};

      // Check if attribValue is an object containing multiple attributes
      if (typeof attribValue === 'object' && attribValue !== null) {
        updateObj = attribValue;
      } else {
        updateObj[attribName] = attribValue;
      }

      const { error } = await supabase
        .from('tasks')
        .update(updateObj)
        .match({ id: taskId });

      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
};

// Update Task Start & Deadline
export const useUpdateTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ taskId, attribName, attribValue }) => {
      if (!attribName) {
        throw new Error('Invalid attribute name');
      }

      const { error } = await supabase
        .from('tasks')
        .update({ [attribName]: attribValue }) // Update the column specified by attribName
        .match({ id: taskId });

      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
};


export const useUpdateTasks = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ processId, attribName, attribValue }) => {
      const updateObj = {};
      updateObj[attribName] = attribValue; // Dynamically set the attribute to update

      const { error } = await supabase
        .from('tasks')
        .update(updateObj)
        .match({ processId }); // Match tasks with the specific processId

      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
};


// Update Task Title
export const useUpdateTaskTitle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ taskId, title }) => {
      const { error } = await supabase
        .from('tasks')
        .update({ title })
        .match({ id: taskId });
      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
};

// Update Task Content
export const useUpdateTaskContent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ taskId, content }) => {
      const { error } = await supabase
        .from('tasks')
        .update({ content })
        .match({ id: taskId });
      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
};

//Create Task
export const useCreateTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newTask) => {
      // Prepare the object to be inserted, conditionally include parentTaskId
      let insertData = {
        processId: newTask.processId,
        title: newTask.title,
      };

      if (newTask.parentTaskId) insertData = { ...insertData, parentTaskId: newTask.parentTaskId };

      // If newTask.id is provided, handle the position adjustment
      if (newTask.id) {
        // Check if the provided ID already exists
        const { data: existingTask, error: getTaskError } = await supabase
          .from('tasks')
          .select('id')
          .eq('id', newTask.id)
          .single();

        if (getTaskError) throw new Error(getTaskError.message);

        if (existingTask) throw new Error('Task with the provided ID already exists.');

        // Update the position of existing tasks with ID >= new task's ID
        const { error: updatePositionError } = await supabase
          .from('tasks')
          .update({ position: supabase.sql`position + 1` })
          .gte('id', newTask.id);

        if (updatePositionError) throw new Error(updatePositionError.message);

        // Include the provided ID and position in the insertData object
        insertData = { ...insertData, id: newTask.id, position: newTask.id };
      }

      // Insert the new task
      const { data, error } = await supabase
        .from('tasks')
        .insert(insertData)
        .select();

      if (error) throw new Error(error.message);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
};
//Create Task
export const useCreateSubtask = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newTask) => {
      // Prepare the object to be inserted, conditionally include parentTaskId
      let insertData = {
        processId: newTask.processId,
        title: newTask.title,
        parentTaskId: newTask.parentTaskId
      };
      const { data, error } = await supabase
        .from('tasks')
        .insert(insertData) // Use the prepared insertData object
        .select(); // Make sure this matches your tasks table schema

      if (error) throw new Error(error.message);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
};

// Create Process
export const useCreateProcess = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ companyUuid, managersAssigned = null }) => {
      const { data, error } = await supabase
        .from('processes')
        .insert({
          title: "New Process",
          startTime: convertToISOWithTimezone(Date.now()),
          totalTasks: 0,
          tasksDone: 0,
          color: '#000000',
          managersAssigned: managersAssigned ? [managersAssigned] : null, // Set managersAssigned to an array with the provided UUID or null
          companyUuid
        })
        .select();

      if (error) throw new Error(error.message);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['processes']);
    },
  });
};

function convertToISOWithTimezone(unixTimestampMs) {
  // Create a Date object from the Unix timestamp in milliseconds
  const date = new Date(unixTimestampMs);
  // Convert to ISO string and append explicit UTC timezone ('Z' indicates UTC)
  const isoStringWithTimezone = date.toISOString();
  return isoStringWithTimezone;
}

// LogIn
export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    // Correctly using mutationFn inside the configuration object
    mutationFn: async ({ email, password }) => {
      let { error } = await supabase.auth.signInWithPassword({ email, password });

      // Handling errors
      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      // Assuming you want to do something with the session token or mark as logged in
      // localStorage.setItem('session', JSON.stringify(session)); // Storing the session for future requests
      queryClient.invalidateQueries(['users']);
    },
    onError: (error) => {
      // Handling login errors
      console.error('Login error:', error);
    }
  });
};

// UseAuth hook adjustment
export const useAuth = () => {
  const [isSession, setIsAuthenticated] = useState(supabase.auth.getSession());

  useEffect(() => {
    // Listen for auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(session);
    });

    // Cleanup function to unsubscribe from the auth state changes when the component unmounts
    return () => authListener?.subscription.unsubscribe();
  }, []);

  return { isSession };
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { error } = await supabase.auth.signOut();
      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      // Clear any stored session information or tokens
      // localStorage.removeItem('session');
      // Optionally invalidate or refetch queries to update the UI
      queryClient.invalidateQueries(['userData']);
    },
    onError: (error) => {
      // Log or handle errors
      console.error('Logout error:', error);
    }
  });
};

export const useGetCurUser = () => {
  const { isSession } = useAuth();
  const { data: userData } = useGetUser(isSession?.user?.id);
  return userData;
}

export const useUpdateUserPassword = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ password }) => {
      const { error } = await supabase.auth.updateUser({ password });
      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      // Refresh the 'users' query to reflect the updates
      queryClient.invalidateQueries(['users']);
    },
    onError: (error) => {
      // Handle errors, such as logging or displaying error messages
      console.error('User update error:', error.message);
    }
  }
  )
};


// Hook to update user details in the custom 'users' table and their password
export const useUserUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uuid, settings }) => {
      const { error } = await supabase
        .from('users')
        .update({ settings })
        .eq('uuid', uuid);

      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      // Invalidate the 'users' query to reflect the updates
      queryClient.invalidateQueries(['users']);
    },
    onError: (error) => {
      // Handle errors, such as logging or displaying error messages
      console.error('User update error:', error.message);
    },
  });
};

// Combined Update for Task isDone and isWarning statuses
export const useUpdateTaskStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ taskId, isDone, isWarning }) => {
      // Build the update object based on provided parameters
      let updateFields = {};
      if (isDone !== undefined) updateFields.isDone = isDone;
      if (isWarning !== undefined) updateFields.isWarning = isWarning;
      const { error } = await supabase
        .from('tasks')
        .update(updateFields)
        .match({ id: taskId });

      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      // Invalidate tasks query to refresh data
      queryClient.invalidateQueries(['tasks']);
    },
  });
};


export const useDeleteProcess = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ processId }) => {
      // Start transaction to delete tasks associated with the process first
      const { error: tasksDeletionError } = await supabase
        .from('tasks')
        .delete()
        .match({ processId: processId });

      if (tasksDeletionError) {
        throw new Error(tasksDeletionError.message);
      }

      // Proceed to delete the process after tasks have been removed
      const { error: processDeletionError } = await supabase
        .from('processes')
        .delete()
        .match({ id: processId });

      if (processDeletionError) {
        throw new Error(processDeletionError.message);
      }
    },
    onSuccess: () => {
      // Invalidate queries to refresh the data after deletion
      queryClient.invalidateQueries(['processes']);
      queryClient.invalidateQueries(['tasks']);
    },
  });
};


export const useDeleteTask = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ taskId }) => {
      const { error: tasksDeletionError } = await supabase
        .from('tasks')
        .delete()
        .match({ id: taskId });
      if (tasksDeletionError) throw new Error(tasksDeletionError.message);
    },
    onSuccess: () => {
      // Invalidate or refetch tasks to reflect the changes
      queryClient.invalidateQueries(['tasks']);
    },
  });
};


export const useSignUp = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ email, password, name, type, title, company, companyUuid, color, manager, settings }) => {
      // Attempt to sign up the user with Supabase Auth
      const { data, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            display_name: name,
            status: 'onboard',
            company,
            notifLink: 'https://organigram.axydavid.com/timeline?notif=true'
          }
        }
      }
      );
      // Check for errors during the signup process
      if (signUpError) throw signUpError;

      // Ensure user creation was successful before attempting to insert into custom users table
      if (data.user) {
        // Insert additional details into your custom users table, linking with the Supabase Auth user ID
        if (manager.uuid === '') manager.uuid = null;

        const { error: insertError } = await supabase.from('users').insert([
          { uuid: data.user.id, managerUuid: [ manager.uuid ], email, name, type, title, company: company.name, companyUuid: companyUuid === '' ? data.user.id : companyUuid, color, settings },
        ]);
        if (insertError) throw insertError;

      } else {
        throw new Error('Signup successful, but no user information returned.');
      }
    },
    onSuccess: () => {
      // Invalidate or refetch relevant queries to ensure the application's state is up-to-date
      queryClient.invalidateQueries(['users']);
    },
    onError: (error) => {
      // Handle signup errors, potentially logging them or informing the user
      console.error('Signup error:', error.message);
    }
  });
};

// Function to request a password reset email
export const useResetPassword = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ email }) => {
      // Trigger a password reset email
      let redirectTo = 'https://organigram.axydavid.com/reset';
      const { error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo });

      // Check for errors during the password reset process
      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      // Optionally, invalidate or refetch queries if necessary
    },
    onError: (error) => {
      // Handle password reset errors, potentially logging them or informing the user
      console.error('Password reset error:', error.message);
    }
  });
};

export const resetPassword = async (email) => {
  try {
    const redirectTo = 'https://organigram.axydavid.com/reset';
    
    const { error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo });

    if (error) {
      throw new Error(error.message);
    }

    // Password reset request successful
    console.log('Password reset email sent');
  } catch (error) {
    // Handle password reset errors, potentially logging them or informing the user
    console.error('Password reset error:', error.message);
  }
};

export const useSendInvitation = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ email }) => {
      // Trigger a password reset email
      let redirectTo = 'https://organigram.axydavid.com/onboard';
      const { error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo });

      // Check for errors during the password reset process
      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      // Optionally, invalidate or refetch queries if necessary
    },
    onError: (error) => {
      // Handle password reset errors, potentially logging them or informing the user
      console.error('Password reset error:', error.message);
    }
  });
};

export const useCreateNotification = () => {
  return useMutation({
    mutationFn: async ({ taskId, processId, toUuid, byUuid, message, companyUuid, type }) => {
      const { error } = await supabase
        .from('notifications')
        .insert([{ taskId, processId, toUuid, byUuid, message, companyUuid, type }]);

      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      // Optionally, invalidate or refetch queries if necessary
    },
    onError: (error) => {
      console.error(error);
    }
  });
};

export const useDeleteNotification = () => {
  return useMutation({
    mutationFn: async ({ taskId }) => {
      // Logic to delete a notification based on taskId or any other identifier
      const { error } = await supabase
        .from('notifications')
        .delete()
        .match({ taskId }); // Example, adjust as necessary

      if (error) throw new Error(error.message);
    },
    // onSuccess and onError handlers as needed
  });
};

export const useGetNotifications = (userId) => {
  return useQuery({
    queryKey: ['notifications', userId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('notifications')
        .select('*') // Assuming each notification contains a 'taskId' field
        .eq('toUuid', userId);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
    enabled: !!userId,
  });
};


export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (notificationId) => {
      const { data, error } = await supabase
        .from('notifications')
        .update({ isSeen: true, isSent: true })
        .match({ id: notificationId });

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
    onSuccess: () => {
      // Invalidate and refetch notifications to reflect the change
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

export const useSendNotificationEmail = () => {
  return useMutation({
    mutationFn: async ({ userId }) => {
      let user = await getUser(userId);
      // Simulate sending email operation
      if (user.settings.notifMail) {
        const sendEmailResult = await supabase.auth.signInWithOtp({ email: user.email }); // Assume this sends the email
        if (sendEmailResult.error) {
          throw new Error(sendEmailResult.error.message);
        }
      }

      // Update all notifications for this user as sent
      const { error } = await supabase
        .from('notifications')
        .update({ isSent: true })
        .match({ toUuid: userId, isSent: false }); // Ensure we only update unsent notifications

      if (error) {
        throw new Error(error.message);
      }
    },
  });
};

export const useGlobalNotifications = () => {
  return useQuery({
    queryKey: ['globalNotifications'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('isSeen', false)
        .eq('isSent', false);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    }
  });
};



export const useResetProcess = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ processId }) => {
      // First, verify if the process is 'taskBased'
      const { data: processData, error: processError } = await supabase
        .from('processes')
        .select('*')
        .eq('id', processId)
        .single();

      if (processError) throw new Error(processError.message);
      if (processData.type !== 'taskBased') {
        throw new Error('Process is not task-based.');
      }

      const { error } = await supabase
        .from('tasks')
        .update({ isDone: false, isWarning: false })
        .eq('processId', processId);

      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
      queryClient.invalidateQueries(['processes']);
    },
  });
};


export const useCalculateProcessCustomFields = (process) => {
  const processId = process.id;
  const customFields = process.fields
    ? Object.keys(process.fields).filter((field) => process.fields[field] === "1" && !['userAssign', 'deadline'].includes(field))
    : [];

  return useQuery({
    queryKey: ['taskAmounts', processId, ...customFields],
    queryFn: async () => {
      const result = {};

      for (const customField of customFields) {
        const { data, error } = await supabase
          .from('tasks')
          .select('*, customFields->$1', [customField])
          .eq('processId', processId);

        if (error) {
          throw new Error(error.message);
        }

        const parseAmount = (value) => {
          if (typeof value === 'string') {
            const numericValue = parseFloat(value.replace(/[kKmMbB]/g, ''));
            if (isNaN(numericValue)) {
              return 0;
            }
            const suffix = value.slice(-1);
            if (suffix.match(/[kK]/)) {
              return numericValue * 1000;
            } else if (suffix.match(/[mM]/)) {
              return numericValue * 1000000;
            } else if (suffix.match(/[bB]/)) {
              return numericValue * 1000000000;
            }
            return numericValue;
          } else if (typeof value === 'number') {
            return value;
          }
          return 0;
        };

        const amounts = data
          .map((task) => parseAmount(task.customFields?.[customField] || 0));
        const totalAmount = amounts.reduce((sum, amount) => sum + amount, 0);
        const doneAmount = data
          .filter((task) => task.isDone)
          .reduce((sum, task) => sum + parseAmount(task.customFields?.[customField] || 0), 0);

        result[customField] = {
          total: totalAmount,
          done: doneAmount,
        };
      }

      return result;
    },
  });
};

export const useGetUserTasks = (userUuid) => {
  return useQuery({
    queryKey: ['userTasks', userUuid],
    queryFn: async () => {
      if (!userUuid) {
        throw new Error("User UUID is undefined");
      }

      const { data, error } = await supabase
        .rpc('get_user_tasks')
        .order('position', { ascending: true });

      if (error) {
        throw new Error(error.message);
      }

      // Convert all keys in the data from snake_case to camelCase
      const camelCaseData = convertKeysToCamelCase(data);
      return camelCaseData;
    },
    enabled: !!userUuid,
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ uuid }) => {
      const { error } = await supabase.rpc('delete_user', { user_id: uuid });

      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
    onError: (error) => {
      console.error('Error removing user:', error.message);
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ uuid, ...updates }) => {
      const { error } = await supabase
        .from('users')
        .update(updates)
        .eq('uuid', uuid);

      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

export const useUpdateUserQuotas = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ uuid, quotas }) => {
      const { error } = await supabase
        .from('users')
        .update({ quotas })
        .eq('uuid', uuid);

      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

export const useGetManagedUsers = (userUuid) => {
  return useQuery({
    queryKey: ['managedUsers', userUuid],
    queryFn: async () => {
      if (!userUuid) {
        throw new Error("User UUID is undefined");
      }

      const { data, error } = await supabase.rpc('get_managed_users', { user_uuid_text: userUuid });

      if (error) {
        throw new Error(error.message);
      }

      // Convert all keys in the data from snake_case to camelCase
      const camelCaseData = convertKeysToCamelCase(data);
      return camelCaseData;
    },
    enabled: !!userUuid,
  });
};

export const useGetUniqueFields = () => {
  return useQuery({
    queryKey: ['uniqueFields'],
    queryFn: async () => {
      const { data, error } = await supabase.rpc('get_unique_fields');

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });
};

export const useUpdateUserMetadata = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ identifier, newMetadata }) => {
      const { error } = await supabase.rpc('update_user_metadata', {
        user_identifier: identifier,
        new_metadata: newMetadata,
      });

      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
    onError: (error) => {
      console.error('Error updating user metadata:', error.message);
    },
  });
};

export const useDisableUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uuid, isDisabled }) => {
      const { error } = await supabase.rpc('disable_user', {
        user_uuid: uuid,
        is_disabled: isDisabled,
      });

      if (error) {
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
    onError: (error) => {
      console.error('Error disabling/enabling user:', error.message);
    },
  });
};