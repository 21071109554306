import React from 'react';
import UserCircles from '../comp/UserCircles';
import { ReactComponent as CheckIcon } from '../icons/check.svg';
import { ReactComponent as WarningIcon } from '../icons/warning.svg';
import { ReactComponent as Star } from '../icons/star.svg';

const Task = ({ task, selectedTaskId, onTaskClick, findUserByuuId }) => {
    const isSelected = task.id === selectedTaskId;

    const handleTaskClick = (e) => {
        e.stopPropagation();
        onTaskClick(task);
    };

    return (
        <div
            onClick={handleTaskClick}
            className={`flex items-center px-4 p-2 task-item text-mine-200 rounded-lg w-full cursor-pointer ${isSelected ? 'task-item-selected' : ''}`}
        >
            {task.usersAssigned.length > 0 && (
                <div className='mr-2 -ml-1'>
                    <UserCircles users={task.usersAssigned.map(findUserByuuId)} />
                </div>
            )}

            <div
                className="flex-1 min-w-0 font-medium my-2 truncate"
                style={{ fontSize: '14px' }}
            >
                {task.title}
            </div>

            {task.isDone && !task.isWarning && <CheckIcon className="task-icon" />}
            {task.isWarning && <WarningIcon className="task-icon" />}
            {task.isMilestone && (<Star className={`ml-2 task-icon ${task.isDone ? 'text-[#59C680]' : ''}`} />)}
        </div>
    );
};

const TaskListLite = ({
    tasks,
    onTaskClick,
    selectedTaskId,
    users,
}) => {
    const findUserByuuId = (userId) => users.find((user) => user.uuid === userId);

    // Reverse the order of the tasks array
    const reversedTasks = [...tasks].reverse();

    return (
        <div className="flex flex-col gap-3 task-list min-w-[200px] max-h-[387px] overflow-y-auto
        scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full scrollbar-thumb-[#4f69b06f] hover:scrollbar-thumb-[#4F69B0] scrollbar-track-transparent">
            {reversedTasks.map((task) => (
                <Task
                    key={task.id}
                    task={task}
                    selectedTaskId={selectedTaskId}
                    onTaskClick={onTaskClick}
                    findUserByuuId={findUserByuuId}
                />
            ))}
        </div>
    );
};

export default TaskListLite;