import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetManagedUsers, useGetUsers, useAuth, useUpdateUser, useDeleteUser, useResetPassword, useDisableUser } from './Database';
import UserCard from './comp/UserCard';
import { ReactComponent as Left } from './icons/left.svg';

const UserManager = () => {
  const { isSession } = useAuth();
  const { data: managedUsers } = useGetManagedUsers(isSession?.user?.id);
  const { data: allUsers } = useGetUsers();
  const updateUser = useUpdateUser();
  const disableUser = useDisableUser();
  const deleteUser = useDeleteUser();
  const resetPassword = useResetPassword();
  const navigate = useNavigate();

  if (!managedUsers || !allUsers) return <div>Loading...</div>;

  const handleDisableUser = (user) => {
    updateUser.mutate({ uuid: user.uuid, isDisabled: !user.isDisabled });
    disableUser.mutate({
      uuid: user.uuid,
      isDisabled: !user.isDisabled,
    });
  };

  const handleDeleteUser = (user) => {
    const confirm = window.confirm(`Are you sure you want to delete ${user.name}?`);
    if (confirm) deleteUser.mutate({ uuid: user.uuid });
  };

  const handleResetUser = (user) => {
    resetPassword.mutate({ email: user.email }, {
      onSuccess: () => {
        window.alert(`Reset email sent!`);
      },
      onError: (error) => window.alert('Password reset error:', error)
    });
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous location
  };

  return (
    <div className="container mx-auto px-4 py-6 ">
      <header className="text-left ml-6 mb-12">
        <div className="flex">
          <div onClick={handleBackClick} className="cursor-pointer flex mr-4">
            <Left className="m-auto special-text" style={{ width: '40px', height: '31px' }} />
          </div>
          <h2 className="text-4xl font-extrabold special-text ">User Management</h2>
        </div>
      </header>

      <div className="gap-6 flex flex-col items-center">
        {managedUsers.length > 0 ? managedUsers.map((user) => (
          <UserCard
            key={user.uuid}
            user={user}
            allUsers={allUsers}
            handleDisableUser={handleDisableUser}
            handleDeleteUser={handleDeleteUser}
            handleResetUser={handleResetUser}
          />
        )) : (
          <h4 className='flex items-center justify-center h-96 text-center text-gray-400 font-bold'>
            No managed users.
          </h4>
        )}
      </div>
    </div>
  );
};

export default UserManager;