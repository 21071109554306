import React, { useState, useEffect, useRef } from 'react';
import { CirclePicker } from 'react-color';
import { ReactComponent as Brush } from '../icons/paintBrush.svg';

const UserCircles = ({
    users,
    circleSize = 28,
    isEdit = false,
    handleColorChangeComplete = null,
    onUserCircleClick = null
}) => {
    const colorPickerRef = useRef(null);
    const popupRef = useRef(null);
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && popupRef.current.contains(event.target)) {
                return;
            }
            else if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setIsColorPickerOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [colorPickerRef]);

    const maxCircles = 1; // Adjust this value to change the maximum number of circles displayed
    const extraCircles = users.length > maxCircles ? users.length - maxCircles : 0;

    return (
        <>
            <div
                className={`${!onUserCircleClick && 'pointer-events-none'}`}
                style={{ position: 'relative', width: `${circleSize}px`, height: `${circleSize}px` }}
                onClick={onUserCircleClick}>
                {users.slice(0, maxCircles).map((user, index) =>
                    (user !== undefined) && (
                        <div className='flex justify-end ' key={user.uuid || index}>
                            {isEdit && (
                                <div className='relative right-6'>
                                    <div
                                        ref={colorPickerRef}
                                        className=" absolute z-10 rounded-full py-1 cursor-pointer"
                                        style={{ color: user.color }}
                                        onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                                    >
                                        <Brush alt="Brush" className='h-[24px] flex-shrink-0 ' />
                                    </div>
                                    <div className=' absolute z-[4] rounded-full py-1 bg-white w-[32px] h-[32px] shadow-lg'></div>
                                </div>
                            )}
                            <UserCircle
                                key={user.uuid}
                                name={user.name}
                                color={user.color}
                                size={circleSize}
                                left={index * 4}
                                zIndex={users.length - index}
                            />
                        </div>
                    )
                )}
                {extraCircles > 0 && (
                    <div className='flex justify-end '>

                        <div
                            className='text-white bg-[#4F69B0]'
                            style={{
                                position: 'absolute',
                                borderRadius: '50%',
                                width: `${circleSize / 1.8}px`,
                                height: `${circleSize / 1.8}px`,
                                maxWidth: '22px',
                                maxHeight: '22px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: `${circleSize === 28 ? '10px' : circleSize === 100 ? '52px' : '13px'}`,
                                fontWeight: 'bold',
                                zIndex: 4,
                                bottom: -3,
                                right: -4,
                            }}
                        >
                            +{extraCircles}
                        </div>
                    </div>
                )}
            </div>
            {isColorPickerOpen && (
                <div
                    ref={popupRef}
                    className="fixed z-10"
                    style={{
                        left: `${colorPickerRef.current.getBoundingClientRect().left}px`,
                        top: `${colorPickerRef.current.getBoundingClientRect().bottom + window.scrollY}px`,
                        transform: 'translate(-30%, 0)'
                    }}
                >
                    <div className="bg-white p-5 rounded-lg shadow-lg">
                        <CirclePicker color={users[0].color}
                            onChangeComplete={(color) => {
                                setIsColorPickerOpen(false);
                                handleColorChangeComplete(color);
                            }} />
                    </div>
                </div>
            )}
        </>
    );
};

export default UserCircles;

const UserCircle = ({ name, color, size = 28, left = 0, zIndex = 1, dashed = false, dashedColor = 'gray' }) => {
    const getInitials = (name) => {
        if (!name) return ''; // Return an empty string if name is undefined or empty

        const trimmedName = name.trim();
        const parts = trimmedName.split(' ');
        if (parts.length > 1) {
            return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
        } else if (parts.length === 1 && parts[0] !== '') {
            return `${parts[0][0]}`.toUpperCase();
        }
        return '';
    };

    const initials = getInitials(name);

    return (
        <div
            className='overflow-hidden shadow-lg'
            style={{
                position: `absolute`,
                width: `${size}px`,
                height: `${size}px`,
                backgroundColor: color,
                left: `${left}px`,
                zIndex: `${zIndex}`,
                color: `${color === 'white' ? '#253766' : 'white'}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                fontSize: `${size === 28 ? '14px' : size === 100 ? '52px' : '24px'}`,
                fontWeight: 'bold',
                textAlign: 'center',
                lineHeight: '60px',
                border: dashed ? `2px dashed ${dashedColor}` : 'none',
            }}
        >
            <div className={`leading-none ${color === '#FFFFFF' && 'text-mine'}`}>{initials}</div>
        </div>
    );
};