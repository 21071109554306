import React, { useMemo } from 'react';

const EmployeeProgressBar = ({ user }) => {
  const calculateProgress = useMemo(() => {
    const quotas = user.quotas || {};
    const quotasDone = user.quotasDone || {};

    let totalQuotas = 0;
    let totalQuotasDone = 0;

    for (const field in quotas) {
      if (quotas.hasOwnProperty(field)) {
        totalQuotas += quotas[field];
        totalQuotasDone += quotasDone[field] || 0;
      }
    }

    const percentageComplete = totalQuotas > 0 ? (totalQuotasDone / totalQuotas) * 100 : 0;
    const completedWidth = 100 * (percentageComplete / 100);

    return {
      percentageComplete,
      completedWidth,
    };
  }, [user.quotas, user.quotasDone]);

  return (
    <div className="bg-white p-4 shadow-lg rounded-lg">
      <h2 className="font-extrabold special-text">Progress</h2>
      <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
        <div
          className="bg-blue-600 h-2.5 rounded-full"
          style={{ width: `${calculateProgress.completedWidth}%` }}
        ></div>
      </div>
      <div className="mt-2 text-right">
        <span className="text-sm font-medium text-blue-600">{`${calculateProgress.percentageComplete.toFixed(2)}%`}</span>
      </div>
    </div>
  );
};

export default EmployeeProgressBar;