import React from 'react';
import { ReadOnlyTaskList } from '../comp/TaskList'; // Ensure path is correct

const Popup = ({ show, mouseX, mouseY, content, onMouseEnter, onMouseLeave }) => {
  if (!show) return null;

  const popupWidth = 200; // Popup width
  const arrowSize = 35; // Arrow size for visual consistency
  let left = mouseX ; // Center the popup horizontally to the cursor
  const top = mouseY + arrowSize; // Position the top of the popup a little below the cursor

  // Adjustments for screen edge cases
  const windowWidth = window.innerWidth;
  if (left < 0) left = 10; // Margin from the left edge of the screen
  if (left + popupWidth > windowWidth) left = windowWidth - popupWidth - 10; // Adjust for the right edge

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} 
    className='p-4 flex pointer-events-none bg-white shadow-lg z-50'
    style={{
      position: 'fixed',
      left: `${left}px`,
      top: `${top}px`,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      flexDirection: 'column',
      alignItems: 'center',
      visibility: show ? 'visible' : 'hidden',
      opacity: show ? 1 : 0,
      transition: 'opacity 0.2s ease-in-out',
      transform:'translateX(-50%)'
    }}>
      <ReadOnlyTaskList id={content} />
      <div style={{
        position: 'absolute',
        top: '-19px', // Position the arrow just above the popup
        left: '50%',
        marginLeft: '-8px', // Center the arrow
        borderWidth: '10px',
        borderStyle: 'solid',
        borderColor: 'transparent transparent white transparent', // Arrow pointing down from the cursor
        
      }} />
    </div>
  );
};

export default Popup;
