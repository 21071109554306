import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserUpdate, useGetCurUser } from './Database';
import { ReactComponent as Left } from './icons/left.svg';

const UserSettings = () => {
  const navigate = useNavigate();
  const currentUser = useGetCurUser();
  const { mutate: updateUser } = useUserUpdate();
  const [settings, setSettings] = useState({
    notifMail: true,
    notif: true,
  });

  useEffect(() => {
    if (currentUser && currentUser.settings) {
      setSettings(currentUser.settings);
    }
  }, [currentUser]);

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous location
  };

  const handleSettingChange = (setting) => {
    const updatedSettings = {
      ...settings,
      [setting]: !settings[setting],
    };

    setSettings(updatedSettings);
    if (currentUser) {
      updateUser({ uuid: currentUser.uuid, settings: updatedSettings });
    }
  };

  return (
    <div className="w-full">
      <header className="text-left ml-6 mb-12">
        <div className="flex">
          <div onClick={handleBackClick} className="cursor-pointer flex mr-4">
            <Left className="m-auto special-text" style={{ width: '40px', height: '31px' }} />
          </div>
          <h2 className="text-4xl font-extrabold special-text ">User Settings</h2>
        </div>
      </header>

      <div className="flex flex-col items-center">
        <Card title="Notifications">
          <div className="flex items-center gap-4 mt-4 cursor-pointer">
            <label htmlFor="notif" className="font-medium text-gray cursor-pointer">
              Browser Notifications
            </label>
            <input
              id="notif"
              type="checkbox"
              checked={settings.notif}
              onChange={() => handleSettingChange('notif')}
              className="form-checkbox h-5 w-5 text-mine accent-mine focus:ring-mine-50 cursor-pointer ml-auto"
            />
          </div>
          <div className="flex items-center gap-4 mt-4 cursor-pointer">
            <label htmlFor="notifMail" className="font-medium text-gray cursor-pointer">
              Email Notifications
            </label>
            <input
              id="notifMail"
              type="checkbox"
              checked={settings.notifMail}
              onChange={() => handleSettingChange('notifMail')}
              className="form-checkbox h-5 w-5 text-mine accent-mine focus:ring-mine-50 cursor-pointer ml-auto"
            />
          </div>

        </Card>
      </div>
    </div>
  );
};

const Card = ({ title, children }) => (
  <div className="bg-white p-4 shadow-lg rounded-lg">
    <h2 className="font-extrabold special-text">{title}</h2>
    {children}
  </div>
);

export default UserSettings;