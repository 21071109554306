import React, { useState, useEffect, useRef } from 'react';
import UserCircles from '../comp/UserCircles';

const UserSelectionPopup = ({
    visible,
    cursorPosition,
    users,
    selectedUsers,
    onUserSelect,
    onClickOut,
    onClickBut,
    toggleRef,
}) => {
    const [localSelectedUsers, setLocalSelectedUsers] = useState(selectedUsers);
    const popupRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && popupRef.current.contains(event.target)) {
                return;
            }
            else if (toggleRef.current && !toggleRef.current.contains(event.target)) {
                onUserSelect(localSelectedUsers); // Apply local changes
                onClickOut(); // Close the popup
            }
            else if (popupRef.current && !popupRef.current.contains(event.target)) {
                onUserSelect(localSelectedUsers); // Apply local changes
                onClickBut();
            }
        };

        if (visible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visible, localSelectedUsers, onUserSelect, onClickOut, onClickBut, toggleRef]);

    const toggleUserSelection = (userUuid) => {
        setLocalSelectedUsers(prev => {
            const isSelected = prev.includes(userUuid);
            const updatedUsers = isSelected
                ? prev.filter(id => id !== userUuid)
                : [...prev, userUuid];

            onUserSelect(updatedUsers); // Call onUserSelect with the updated users

            return updatedUsers;
        });
    };

    if (!visible) {
        return null;
    }

    const popupStyle = {
        position: 'absolute',
        left: `${cursorPosition.x !== '50%' ? cursorPosition.x : 0}px`,
        top: `${cursorPosition.y}px`, // Use cursor y position
        transform: `translateX(${cursorPosition.x === '50%' ? cursorPosition.x : '0px'})`,
        zIndex: 1000,
    };

    return (
        <div ref={popupRef} style={popupStyle}
            className="p-4 bg-white shadow-lg max-w-xs rounded-xl" onClick={(e) => e.stopPropagation()}>
            <div className="user-list space-y-2">
                {users.map(user => {
                    const isSelected = localSelectedUsers.some(u => u === user.uuid);
                    return (
                        <div
                            key={user.uuid}
                            onClick={() => toggleUserSelection(user.uuid)}
                            className={`flex p-2 cursor-pointer rounded-lg gap-1.5 font-medium task-item text-mine-200 ${isSelected ? 'task-item-selected ' : ''}`}
                            style={{ fontSize: '14px' }}
                        >
                            <UserCircles users={[user]} />
                            <div className='flex-grow'>{user.name}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UserSelectionPopup;
