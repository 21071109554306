// Navbar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
// import { ReactComponent as DashboardIcon } from './icons/dashboard.svg';
// // import { ReactComponent as TasksIcon } from './icons/tasks.svg';
// import { ReactComponent as PlanningIcon } from './icons/planning.svg';
// import { ReactComponent as AnalyticsIcon } from './icons/analytics.svg';
// import { ReactComponent as MessagesIcon } from './icons/messages.svg';
// import { ReactComponent as FinanceIcon } from './icons/finance.svg';
import { ReactComponent as Logo } from './icons/logo.svg';

const Navbar = () => {
  // const location = useLocation();

  // Function to determine if the route is active
  // const isActive = (path) => location.pathname === path;

  return (
    <div className="flex h-screen" >
      <nav className="navbar w-45 bg-white p-8 font-sans" style={{backgroundColor: `#f8f9fa`}}>
        <div className="mb-10 pl-3 justify-center" >
        <NavLink to="/timeline" className={`flex items-center rounded`}>
          <Logo alt="Logo" style={{ height: '118px' }}/>
        </NavLink>
        </div>
        
        {/* <NavLink to="/timeline" className={`flex items-center py-2 px-4 rounded text-sm ${isActive('/timeline') ? 'font-extrabold' : 'font-bold'} `}>
          <DashboardIcon fill={isActive('/timeline') ? '#253766' : '#A7B7CE'} className="h-6 w-6" />
          <span className={`ml-3 ${isActive('/timeline') ? 'text-#253766' : ''}`}>Timeline</span>
        </NavLink>
        <NavLink to="/planning" className={({ isActive }) =>
          `flex items-center py-2 px-4 rounded text-sm ${isActive ? 'font-extrabold' : 'font-bold'} `}>
          <PlanningIcon fill={isActive('/planning') ? '#253766' : '#A7B7CE'} className="h-6 w-6" />
          <span className={`ml-3 ${isActive('/planning') ? 'text-#253766' : ''}`}>Planning</span>
        </NavLink>
        <NavLink to="/analytics" className={({ isActive }) =>
          `flex items-center py-2 px-4 rounded text-sm ${isActive ? 'font-extrabold' : 'font-bold'} `}>
          <AnalyticsIcon fill={isActive('/analytics') ? '#253766' : '#A7B7CE'} className="h-6 w-6" />
          <span className={`ml-3 ${isActive('/analytics') ? 'text-#253766' : ''}`}>Analytics</span>
        </NavLink>
        <NavLink to="/messages" className={({ isActive }) =>
          `flex items-center py-2 px-4 rounded text-sm ${isActive ? 'font-extrabold' : 'font-bold'} `}>
          <MessagesIcon fill={isActive('/messages') ? '#253766' : '#A7B7CE'} className="h-6 w-6" />
          <span className={`ml-3 ${isActive('/messages') ? 'text-#253766' : ''}`}>Messages</span>
        </NavLink>
        <NavLink to="/" className={({ isActive }) =>
          `flex items-center py-2 px-4 rounded text-sm ${isActive ? 'font-extrabold' : 'font-bold'} `}>
          <FinanceIcon fill={isActive('/') ? '#253766' : '#A7B7CE'} className="h-6 w-6" />
          <span className={`ml-3 ${isActive('/') ? 'text-#253766' : ''}`}>Finance</span>
        </NavLink> */}
      </nav>
    </div>
  );
};

export default Navbar;