import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TaskListLite from './comp/TaskListLite'; // Import the new TaskListLite component
import QuillEditor from './comp/QuillEditor';
import EmployeeProgressBar from './comp/EmployeeProgressBar';
import ErrorBoundary from './comp/ErrorBoundary';
import { ReactComponent as CheckIcon } from './icons/check.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { useGetUserTasks, useUpdateTaskStatus, useGetCurUser, useGetUsers } from './Database';
import { ReactComponent as Left } from './icons/left.svg';

const TaskView = () => {
  const currentUser = useGetCurUser();
  const { data: tasks, isLoading: isTasksLoading, error: tasksError } = useGetUserTasks(currentUser?.uuid);
  const { data: users } = useGetUsers(); // Fetch all users

  const navigate = useNavigate();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [taskContent, setTaskContent] = useState("");
  const [isContentModified, setIsContentModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { mutate: updateTaskStatus } = useUpdateTaskStatus();

  const handleTaskClick = (task) => {
    setSelectedTaskId(task.id);
  };

  const handleTaskContentChange = (content) => {
    setTaskContent(content);
    setIsContentModified(true);
  };

  const toggleTaskIsDone = (task) => {
    updateTaskStatus({
      taskId: task.id,
      isDone: !task.isDone,
      isWarning: false,
    });
  };

  const toggleTaskIsWarning = (task) => {
    updateTaskStatus({
      taskId: task.id,
      isWarning: !task.isWarning,
      isDone: false,
    });
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous location
  };

  // Save task content with debounce
  useEffect(() => {
    const debounceSaveTimer = setTimeout(() => {
      if (isContentModified && selectedTaskId) {
        setIsSaving(true);

        // Simulate the save operation with a delay
        setTimeout(() => {
          setIsContentModified(false);
          setIsSaving(false);
        }, 1500);
      }
    }, 1500);

    return () => clearTimeout(debounceSaveTimer);
  }, [taskContent, isContentModified, selectedTaskId]);

  // Effect for updating selected task when selectedTaskId changes
  useEffect(() => {
    if (selectedTaskId && tasks?.length > 0) {
      const selectedTask = tasks.find(task => task.id === selectedTaskId);
      if (selectedTask) {
        setTaskContent(selectedTask.content || '');
      }
    }
  }, [selectedTaskId, tasks]);

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      setSelectedTaskId(tasks[tasks.length - 1].id);
    }
  }, [tasks]);

  if (isTasksLoading) {
    return <div>Loading tasks...</div>;
  }

  if (tasksError) {
    return <div>Error: {tasksError.message}</div>;
  }

  return (
    <div className="w-full">
      <header className="text-left ml-6">
        <div className="flex">
          {currentUser?.type !== 'employee' && (
            <div onClick={handleBackClick} className="cursor-pointer flex mr-4">
              <Left className="m-auto special-text" style={{ width: '40px', height: '31px' }} />
            </div>
          )}

          <h2 className="text-4xl font-extrabold special-text ">Task View</h2>
        </div>
      </header>
      <ErrorBoundary>
        <div className="flex mt-10 gap-6 items-center lg:items-stretch justify-center">
          <div className="flex bg-white p-6 rounded-xl min-h-[270px] min-w-[500px] max-w-3xl shadow-lg justify-center">
            {tasks && tasks.length > 0 ? (
              <>
                <TaskListLite
                  tasks={tasks}
                  onTaskClick={handleTaskClick}
                  selectedTaskId={selectedTaskId}
                  users={users}
                />
                <div className="relative flex-grow pl-6">
                  <QuillEditor value={taskContent} onChange={handleTaskContentChange} />
                  {isSaving && (
                    <div className="absolute right-0 bottom-0 mb-2 ml-2 flex items-center bg-white rounded-xl">
                      <div className="h-4 w-4 border-2 border-gray-300 border-t-transparent border-solid rounded-full animate-spin"></div>
                      <span className="ml-2 text-sm text-gray-300 font-medium">Saving...</span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <h4 className='flex items-center justify-center text-center text-gray-400 font-bold'>
                No tasks available.
              </h4>
            )}
          </div>

          {selectedTaskId && (
            <div className="flex flex-col gap-4">
              <EmployeeProgressBar user={currentUser} />

              <div className="bg-white p-4 shadow-lg rounded-lg min-w-[200px]">
                <h2 className="font-extrabold special-text">Status</h2>
                <div
                  className="flex gap-1 p-2 items-center font-medium text-gray w-full cursor-pointer hover:bg-mine-10 rounded-lg"
                  onClick={() => toggleTaskIsDone(tasks.find(task => task.id === selectedTaskId))}
                >
                  {tasks.find(task => task.id === selectedTaskId)?.isDone ? (
                    <div className="flex items-center gap-1.5">
                      <CheckIcon className="task-icon" />
                      Done
                    </div>
                  ) : (
                    'Finish Task'
                  )}
                </div>
                <div
                  className="flex gap-1 p-2 items-center font-medium text-gray w-full cursor-pointer hover:bg-mine-10 rounded-lg"
                  onClick={() => toggleTaskIsWarning(tasks.find(task => task.id === selectedTaskId))}
                >
                  {tasks.find(task => task.id === selectedTaskId)?.isWarning ? (
                    <div className="flex items-center gap-1.5">
                      <WarningIcon className="task-icon" />
                      Warning
                    </div>
                  ) : (
                    'Set Warning'
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default TaskView;