import React, { useState, useRef, useEffect, useCallback } from 'react';
import { CirclePicker } from 'react-color';
import { ReactComponent as Settings } from '../icons/settings.svg';
import { useUpdateProcess, useGetUsers, useAuth } from '../Database';
import UserCircles from '../comp/UserCircles';
import UserSelectionPopup from './UserSelectionPopup';

const ProcessPopup = ({ onClose, currentColor, onOptionSelect, process }) => {
  const { isSession } = useAuth();
  const currentUser = isSession?.user;
  const [visible, setVisible] = useState(false);
  const [restartOption, setRestartOption] = useState(process.restart ? process.restart : 'onDone');
  const buttonRef = useRef();
  const popupRef = useRef();
  const mgrRef = useRef();
  const [restartPeriod, setRestartPeriod] = useState(process.restart !== 'onDone' && process.restart !== 'onDeadline' && process.restart ? process.restart : 1);
  const updateProcess = useUpdateProcess();
  const [isRecurring, setIsRecurring] = useState(!process.restart ? false : true);
  const [fields, setFields] = useState(process.fields || {});
  const [fieldsSuffix, setFieldsSuffix] = useState(process.fieldsSuffix || {});
  const { data: allUsers } = useGetUsers();
  const managersUsers = allUsers.filter(user => user.type === 'manager');
  const [showManagerSelectionPopup, setShowManagerSelectionPopup] = useState(false);

  const toggleVisibility = () => {
    if (visible === 2) {
      setVisible(false);
      if (showManagerSelectionPopup) {
        setShowManagerSelectionPopup(false);
      }
    } else {
      setVisible(!visible);
    }
  };

  const toggleManagerSelectionPopup = useCallback((event) => {
    event.stopPropagation();
    if (showManagerSelectionPopup === 2 || (!visible && showManagerSelectionPopup)) {
      setShowManagerSelectionPopup(false);
    } else {
      setShowManagerSelectionPopup(!showManagerSelectionPopup);
    }
  }, [showManagerSelectionPopup, visible]);

  

  const handleRecurringChange = (event) => {
    let isChecked = event.target.checked;
    setIsRecurring(isChecked);
    updateProcess.mutate(
      { processId: process.id, update: { restart: !isChecked ? null : restartOption } },
      {
        onSuccess: () => { },
        onError: (error) => { console.error('Error updating color:', error); }
      }
    );
  };

  const handleRestartChange = (event) => {
    let selectedOption = event.target.value;
    setRestartOption(selectedOption);
    if (onOptionSelect) onOptionSelect(selectedOption);
    updateProcess.mutate(
      { processId: process.id, update: { restart: selectedOption } },
      {
        onSuccess: () => { },
        onError: (error) => { console.error('Error updating color:', error); }
      }
    );
  };

  const handleFieldChange = (field, value) => {
    setFieldsSuffix((prevSuffixes) => ({ ...prevSuffixes, [field]: value }));
    updateProcess.mutate(
      { processId: process.id, update: { fieldsSuffix: { ...fieldsSuffix, [field]: value } } },
      {
        onSuccess: () => { },
        onError: (error) => { console.error('Error updating fields:', error); }
      }
    );
  };

  const handleFieldToggle = (field) => {
    setFields((prevValues) => ({
      ...prevValues,
      [field]: prevValues[field] === '1' ? false : '1',
    }));
    updateProcess.mutate(
      { processId: process.id, update: { fields: { ...fields, [field]: fields[field] === '1' ? false : '1' } } },
      {
        onSuccess: () => { },
        onError: (error) => { console.error('Error updating fields:', error); }
      }
    );
  };

  const handleManagerSelect = useCallback((selectedManagers) => {
    const selUser = allUsers.find(user => user.uuid === currentUser?.id);
    let managersToAssign = [];

    if (selectedManagers.length === 0) {
      // Case 1: No managers selected - assign the company UUID or the user's UUID
      managersToAssign = selUser?.companyUuid ? [selUser.companyUuid] : [currentUser?.id];
    }
    if (selectedManagers.length >= 1) {
      if (selectedManagers.length > 1) managersToAssign = selectedManagers.filter(managerUuid => managerUuid !== selUser?.companyUuid);
      else managersToAssign = selectedManagers;
    }

    updateProcess.mutate(
      { processId: process.id, update: { managersAssigned: managersToAssign } },
      {
        onError: (error) => {
          console.error('Failed to update process managers:', error);
        },
      }
    );
  }, [allUsers, currentUser, process, updateProcess]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && popupRef.current.contains(event.target)) {
        return;
      } else if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setVisible(false);
        const selectedUsers = process.managersAssigned || [];
        handleManagerSelect(selectedUsers); // Call handleManagerSelect with the current selected users
        setShowManagerSelectionPopup(false); // Close the UserSelectionPopup
      } else if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (visible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible, buttonRef, onClose, handleManagerSelect, process.managersAssigned]);

  if (!currentColor) return null;

  return (
    <>
      <div
        ref={buttonRef}
        className={`w-10 h-10 rounded-full cursor-pointer m-auto items-center justify-center flex absolute bg-red`}
        onClick={toggleVisibility}
        style={{ color: currentColor }}
      >
        <Settings alt="Settings" className={`h-[24px] flex-shrink-0 ${process.percentageComplete > 95 && 'opacity-0'}`} />
      </div>
      {visible && (
        <div ref={popupRef} className="bg-white rounded-xl text-left shadow-xl flex w-max p-6 px-8 gap-8 -translate-x-[70.5%] translate-y-14">
          <div>
            {process.managersAssigned && process.managersAssigned.length > 0 && (
              <div className=''>
                <h2 className="font-extrabold special-text mb-3" id="modal-title">
                  Process Manager{process.managersAssigned.length > 1 && 's'}
                </h2>
                <div className='flex justify-center'>
                  <div className="cursor-pointer" ref={mgrRef}>
                    <UserCircles
                      users={process.managersAssigned.map(userUuid => allUsers.find(user => user.uuid === userUuid))}
                      onUserCircleClick={toggleManagerSelectionPopup}
                      circleSize={52}
                    />
                  </div>
                </div>
              </div>
            )}
            <h2 className="mt-5 mb-3 font-extrabold special-text" id="modal-title">
              Process Settings
            </h2>
            <div className="flex flex-col">
              <div className="flex gap-2 max-w-60 flex-wrap">
                <button
                  className={`border border-mine text-mine hover:bg-mine-700 hover:text-white w-[110px] justify-center font-bold py-2 px-4 rounded-full flex items-center space-x-2 force-shadow-lg ${fields.userAssign === '1' ? 'bg-mine hover:bg-mine-700 text-white' : ''}`}
                  onClick={() => handleFieldToggle('userAssign')}
                >
                  <span>User</span>
                </button>
                <button
                  className={`border border-mine text-mine hover:bg-mine-700 hover:text-white w-[110px] justify-center font-bold py-2 px-4 rounded-full flex items-center space-x-2 force-shadow-lg ${fields.deadline === '1' ? 'bg-mine hover:bg-mine-700 text-white' : ''}`}
                  onClick={() => handleFieldToggle('deadline')}
                >
                  <span>Deadline</span>
                </button>
                <button
                  className={`border border-mine text-mine hover:bg-mine-700 hover:text-white w-[110px] justify-center font-bold py-2 px-4 rounded-full flex space-x-2 force-shadow-lg ${fields.subTasks === '1' ? 'bg-mine hover:bg-mine-700 text-white' : ''}`}
                  onClick={() => handleFieldToggle('subTasks')}
                >
                  <span>Sub-tasks</span>
                </button>
                <button
                  className={`border border-mine text-mine hover:bg-mine-700 hover:text-white w-[110px] justify-center font-bold py-2 px-4 rounded-full flex space-x-2 force-shadow-lg ${fields.milestone === '1' ? 'bg-mine hover:bg-mine-700 text-white' : ''}`}
                  onClick={() => handleFieldToggle('milestone')}
                >
                  <span>Milestones</span>
                </button>
              </div>
              <div className="">
                <h3 className="mt-5 mb-2 font-extrabold special-text">Custom Fields</h3>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <button
                      className={`border border-mine text-mine hover:bg-mine-700 hover:text-white w-[110px] justify-center font-bold py-2 px-4 rounded-full flex items-center space-x-2 force-shadow-lg ${fields.budget === '1' ? 'bg-mine hover:bg-mine-700 text-white' : ''}`}
                      onClick={() => handleFieldToggle('budget')}
                    >
                      <span>Budget</span>
                    </button>
                    <input
                      type="text"
                      id="budgetSuffix"
                      value={fieldsSuffix.budget || ''}
                      onChange={(e) => handleFieldChange('budget', e.target.value)}
                      className="p-1 border rounded w-14"
                      placeholder="Suffix"
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      className={`border border-mine text-mine hover:bg-mine-700 hover:text-white w-[110px] justify-center font-bold py-2 px-4 rounded-full flex items-center space-x-2 force-shadow-lg ${fields.extBudget === '1' ? 'bg-mine hover:bg-mine-700 text-white' : ''}`}
                      onClick={() => handleFieldToggle('extBudget')}
                    >
                      <span>External</span>
                    </button>
                    <input
                      type="text"
                      id="extBudgetSuffix"
                      value={fieldsSuffix.extBudget || ''}
                      onChange={(e) => handleFieldChange('extBudget', e.target.value)}
                      className="p-1 border rounded w-14"
                      placeholder="Suffix"
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      className={`border border-mine text-mine hover:bg-mine-700 hover:text-white w-[110px] justify-center font-bold py-2 px-4 rounded-full flex items-center space-x-2 force-shadow-lg ${fields.hours === '1' ? 'bg-mine hover:bg-mine-700 text-white' : ''}`}
                      onClick={() => handleFieldToggle('hours')}
                    >
                      <span>Hours</span>
                    </button>
                    <input
                      type="text"
                      id="hoursSuffix"
                      value={fieldsSuffix.hours || ''}
                      onChange={(e) => handleFieldChange('hours', e.target.value)}
                      className="p-1 border rounded w-14"
                      placeholder="Suffix"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-[2px] h-40 rounded-full my-auto"
            style={{ background: 'linear-gradient(to bottom, transparent, #8896ac40, transparent)' }}
          />
          <div className="">
            <div className=" w-full">
              <h2 className="font-extrabold special-text" id="modal-title">
                Color
              </h2>
              <div className="mt-2">
                <CirclePicker color={currentColor} onChangeComplete={(newColor) => onClose(newColor.hex)} />
              </div>
            </div>
            <div className="mt-4">
              <h3 className="mb-2 font-extrabold special-text">Recurring Process</h3>
              <div>
                <input
                  id="reoccuring"
                  name="reoccuring"
                  type="checkbox"
                  checked={isRecurring}
                  onChange={handleRecurringChange}
                  className="h-4 w-4 accent-mine focus:ring-mine-50 border-gray-200 rounded"
                />
                <label htmlFor="reoccuring" className="ml-2 cursor-pointer" style={{ fontFamily: 'Roboto', fontSize: '15px' }}>
                  Recurring Process
                </label>
                <fieldset className="ml-4 -mt-2">
                  <div className="mt-2 space-y-2">
                    <div className="" style={{ marginTop: 0 }}>
                      <label className="flex items-center">
                        <input
                          disabled={!isRecurring}
                          className="bg-mine form-radio"
                          type="radio"
                          name="restartOption"
                          value="onDone"
                          checked={restartOption === 'onDone'}
                          onChange={handleRestartChange}
                        />
                        <span className="ml-2 cursor-pointer" style={{ fontFamily: 'Roboto', fontSize: '15px' }}>
                          Restart when all tasks are done
                        </span>
                      </label>
                    </div>
                    <div className="" style={{ marginTop: 0 }}>
                      <label className="flex items-center">
                        <input
                          disabled={!isRecurring}
                          type="radio"
                          name="restartOption"
                          value={restartPeriod}
                          checked={restartOption !== 'onDeadline' && restartOption !== 'onDone'}
                          onChange={handleRestartChange}
                          className="form-radio"
                        />
                        <span className="ml-2 cursor-pointer" style={{ fontFamily: 'Roboto', fontSize: '15px' }}>
                          Restart every
                        </span>
                        <input
                          type="number"
                          id="customRestartPeriod"
                          name="customRestartPeriod"
                          min="1"
                          value={restartPeriod}
                          onChange={(e) => {
                            setRestartPeriod(e.target.value);
                            handleRestartChange(e);
                          }}
                          className="w-5 weekNr text-center"
                          disabled={!isRecurring}
                        />
                        <div className="cursor-pointer">week{restartPeriod > 1 ? 's' : ''}</div>
                      </label>
                    </div>
                    <div className="" style={{ marginTop: 0 }}>
                      <label className="flex items-center">
                        <input
                          disabled={!isRecurring}
                          type="radio"
                          name="restartOption"
                          value="onDeadline"
                          checked={restartOption === 'onDeadline'}
                          onChange={handleRestartChange}
                          className="form-radio"
                        />
                        <span className="ml-2 cursor-pointer" style={{ fontFamily: 'Roboto', fontSize: '15px' }}>
                          Restart on process deadline
                        </span>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <UserSelectionPopup
            visible={showManagerSelectionPopup}
            cursorPosition={{ x: '50%', y: 125 }}
            selectedUsers={process.managersAssigned || []}
            users={managersUsers}
            onUserSelect={handleManagerSelect}
            onClickOut={() => setShowManagerSelectionPopup(false)}
            onClickBut={() => setShowManagerSelectionPopup(2)}
            toggleRef={mgrRef}
          />
        </div>
      )}
    </>
  );
};

export default ProcessPopup;