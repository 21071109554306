// Planning.js
import React from 'react';
import DraggableTimeline from './comp/DraggableTimeline';

const Planning = () => {
  // Define the styled timeline component (BoxComponent) here and pass it as a child
  const TimelineStyle = {
    position: 'absolute',
    left: '0px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '1000px', // Timeline width is twice the container width
    height: '300px',
    background: 'linear-gradient(to right, #33ccff, #ff99cc)',
    cursor: 'grab',
  };

  return (
    <DraggableTimeline>
      <div style={TimelineStyle}></div>
    </DraggableTimeline>
  );
};

export default Planning;
