import React, { useState } from 'react';
import { useLogin } from './Database'; // Ensure you have a useLogin hook implemented in Database.js
import { Link } from 'react-router-dom';

const LogIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const loginMutation = useLogin();
  // Email and password state management remains the same

  const handleLogin = (event) => {
    event.preventDefault();
    loginMutation.mutate({ email, password }, {
      onSuccess: () => {
      },
      onError: (error) => {
        window.alert(error)
      }
    });
  };

  return (
    <div className="container-lg lg:container m-auto ">
      <div className="max-w-md m-auto">
        <div>
          <h2 className="text-center text-3xl font-extrabold special-text">
            Welcome
          </h2>
        </div>
        <form onSubmit={handleLogin} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">Email address</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
                placeholder="Email address"
                style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='pt-3'>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
                placeholder="Password"
                style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 accent-gray-400 hover:accent-mine focus:ring-mine-50 border-gray-200 rounded"
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm text-gray-400 hover:text-mine-500 cursor-pointer"
                style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <Link
                to="/passreset"
                className="font-medium text-gray-400 hover:text-mine-500"
                style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-mine hover:bg-mine-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mine-50"
              style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '20px' }}
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
