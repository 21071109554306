import React from 'react';
import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import TopBar from './TopBar';
import Timeline from './Timeline';
import Planning from './Planning';
import ProcessManager from './ProcessManager';
import UserManager from './UserManager';
import UserResetPassword from './UserResetPassword';
import LogIn from './LogIn';
import LogPass from './LogPass';
import LogOut from './LogOut';
import UserCreate from './UserCreate';
import { useAuth, useGetCurUser } from './Database';
import UserOnboard from './UserOnboard';
import UserInvite from './UserInvite';
import UserSettings from './UserSettings';
import TaskView from './TaskView';

function isObject(variable) {
    return typeof variable === 'object' &&
        variable !== null &&
        Object.prototype.toString.call(variable) !== '[object Promise]';
}

export function App() {
    const { isSession } = useAuth();
    const isAuthenticated = isObject(isSession);
    const currentUser = useGetCurUser();

    return (
        <Router>
            <div className="flex">
                {isAuthenticated && (
                    <div className="fixed top-0 left-0 h-screen z-30 lg:block hidden" >
                        <Navbar />
                    </div>
                )}
                <div className="flex-1 flex flex-col">
                    {isAuthenticated && (
                        <div className="z-10 ml-auto h-0" style={{ width: 'max-content' }}>
                            <TopBar />
                        </div>
                    )}
                    <div className={`${isAuthenticated && 'lg:pl-64'}`}>
                        <div className={`flex h-screen ${isAuthenticated && 'pt-6 lg:pt-20'}`}>
                            <Routes basename="/" >
                                {!isAuthenticated && <>
                                    <Route path="/" element={<LogIn />} />
                                    <Route path="/passreset" element={<LogPass />} />
                                    <Route path="/logout" element={<LogOut />} />
                                    <Route path="/onboard" element={<UserOnboard />} />
                                    {/* <Route path="/signup" element={<SignUp />} /> */}
                                    <Route path="*" element={<Navigate replace to="/" />} />
                                </>}
                                {isAuthenticated && <>
                                    {currentUser && currentUser.type === 'employee' ? (
                                        <Route path="/" element={<TaskView replace to="/taskview" />} />
                                    ) : (
                                        <Route path="/" element={<Navigate replace to="/timeline" />} />
                                    )}                                    
                                    <Route path="/logout" element={<LogOut />} />
                                    <Route path='/timeline' element={<Timeline />} />
                                    <Route path='/planning' element={<Planning />} />
                                    <Route path="/invite" element={<UserInvite />} />
                                    <Route path="/onboard" element={<UserOnboard />} />
                                    <Route path="/process" element={<ProcessManager />} />
                                    <Route path="/manager" element={<UserManager />} />
                                    <Route path="/settings" element={<UserSettings />} />
                                    <Route path="/reset" element={<UserResetPassword />} />
                                    <Route path="/create" element={<UserCreate />} />
                                    <Route path="/taskview" element={<TaskView />} />
                                    {/* Redirect unauthenticated users */}
                                    <Route path="*" element={<Navigate replace to="/" />} />
                                </>}
                                {/* Handle the case when none of the above routes are matched */}
                                {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
}
