import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSignUp, useSendInvitation, useGetCurUser, useUpdateUserMetadata } from './Database'; // Ensure resetPasswordForEmail is exported from Database.js

const UserInvite = () => {
  const curUser = useGetCurUser();
  const signUp = useSignUp();
  const sendInvitation = useSendInvitation(); // Assuming you have a hook for resetting the password
  const updateUserMetadata = useUpdateUserMetadata();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    email: '',
    name: '',
    type: 'manager',
    title: '',
    company: { name: '' },
    companyUuid: '',
    manager: {}, // Default to the current user's ID
    color: '#000000',
  });
  // Wait for the session to be determined


  useEffect(() => {
    if (curUser) {
      setUserDetails(currentDetails => ({
        ...currentDetails,
        manager: { uuid: curUser.uuid, name: curUser.name, color: curUser.color },
        companyUuid: curUser.type !== 'company' ? curUser.companyUuid : curUser.uuid,
        company: { name: curUser.type !== 'company' ? curUser.company : curUser.name },
        type: curUser.type !== 'company' ? 'employee' : 'manager',
      }));
    }
  }, [curUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the email is not provided and return early if it's true
    if (!userDetails.email || userDetails.email.length === 0) {
      console.error("Email is required to send the invite.");
      return;
    }

    // Additional check for session availability
    if (!curUser) {
      console.error("Session not available, can't proceed with the invite.");
      return;
    }
    const tempPassword = Math.random().toString(36).slice(-8);

    signUp.mutate({ ...userDetails, password: tempPassword }, {
      onSuccess: () => {
        // Assuming you need the user's ID from the sign-up process
        //`${window.location.origin}/onboard`
        sendInvitation.mutate({ email: userDetails.email }, {
          onSuccess: () => {
            updateUserMetadata.mutate({ identifier: userDetails.email, newMetadata: { 'status': 'reset' } },
              {
                onSuccess: () => {
                  window.alert(`Invitation sent!`);
                  navigate('/invite');
                },
                onError: (error) => window.alert('Error updating user metadata:', error),
              }
            );
          },
          onError: (error) => window.alert('Password reset error:', error)
        });
      },
      onError: (error) => window.alert('Signup error:', error)
    });
  };

  if (!curUser) return <div>Loading...</div>;

  return (
    <div className="container-lg lg:container m-auto relative top-[-5rem] left-[-4rem]">
      <div className="max-w-md m-auto">
        <h2 className="text-center text-3xl font-extrabold text-mine capitalize">Invite{' ' + userDetails.type}</h2>
        <form className="mt-8 space-y-3" disabled={true} onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            className="appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
            style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
            value={userDetails.email}
            onChange={handleChange}
          />
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            required
            className="col-span-3 appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
            style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
            value={userDetails.name}
            onChange={handleChange}
          />
          <div className="grid grid-cols-4 gap-3">
            <input
              type="text"
              name="title"
              placeholder="Position"
              className="col-span-3 appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
              style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
              value={userDetails.title}
              onChange={handleChange}
            />
            <select
              name="type"
              required
              className="rounded-full text-gray-900 px-4 sm:text-sm border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }} // Adjust padding to match input fields
              value={userDetails.type}
              onChange={handleChange}
            >
              <option value="manager">Manager</option>
              <option value="employee" >Employee</option>
            </select>
          </div>
          <div className="flex items-center gap-3"></div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-mine hover:bg-mine-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '20px' }}
          >
            Send Invitation
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserInvite;
