import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogout } from './Database'; // Adjust the path as necessary

const LogOut = () => {
  const navigate = useNavigate();
  const logoutMutation = useLogout();

  useEffect(() => {
    // Automatically call logout on component mount
    logoutMutation.mutate({}, {
      onSuccess: () => {
        // Navigate the user to the login page or another appropriate page after logout
        navigate('/');
      },
      onError: (error) => {
        // Optionally handle errors, such as displaying a notification to the user
        console.error('Logout error:', error);
      }
    });
  }, [logoutMutation, navigate]); // Add dependencies to the useEffect hook

  // Optionally, provide feedback or a loading indicator here
  return (
    <div className="settings-container p-4 max-w-xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Logging out...</h2>
      {/* You can add a spinner or any loading indicator here */}
    </div>
  );
};

export default LogOut;
