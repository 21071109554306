import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskList from './comp/TaskList';
import UserSelectionPopup from './comp/UserSelectionPopup';
import QuillEditor from './comp/QuillEditor';
import DatePickerPopup from './comp/DatePickerPopup';
import ProgressBar from './comp/ProgressBar';
import ErrorBoundary from './comp/ErrorBoundary';
import { ReactComponent as User } from './icons/userCircle.svg';
import { ReactComponent as Time } from './icons/Clock.svg';
import { ReactComponent as Coins } from './icons/coins.svg';
import { ReactComponent as CoinsOut } from './icons/coinsOut.svg';
import { ReactComponent as DeadLine } from './icons/calendar.svg';
import { ReactComponent as Left } from './icons/left.svg';
import { ReactComponent as CheckIcon } from './icons/check.svg'; // Adjust the path
import { ReactComponent as WarningIcon } from './icons/warning.svg'; // Adjust the path
import { ReactComponent as Star } from './icons/star.svg';
import { ReactComponent as Pen } from './icons/penBold.svg'; // Adjust the path
import { useCreateNotification, useDeleteNotification, useGetCurUser } from './Database'; // Adjust the path as necessary
import { useQueryClient } from '@tanstack/react-query';

import {
  useGetProcess,
  useGetTasks,
  useGetUsers,
  useCreateTask,
  useCreateSubtask,
  useUpdateTaskUserAssignment,
  useUpdateTask,
  useUpdateTaskTitle,
  useUpdateTaskContent,
  useUpdateTaskStatus,
  useDeleteTask,
  useUpdateProcess,
  useUpdateTaskMilestone
} from './Database';


const Card = ({ title, children, className }) => (
  <div className={`bg-white p-4 shadow-lg rounded-lg ${className}`} style={{ width: '200px' }}>
    <h2 className="font-extrabold special-text">{title}</h2>
    {children}
  </div>
);

const formatDate = (dateString) => {
  if (!dateString) return 'Infinity';
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit', month: 'short', year: '2-digit',
  }).format(date);

  // Insert apostrophe before the year
  return formattedDate.replace(/(\d{2})$/, "'$1");
};

const CustomField = ({ fieldKey, value, suffix, defaultText, onValueChange }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    onValueChange(fieldKey, inputValue);
  };

  const handleInputFocus = () => {
    setIsEditing(true);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    } else if (e.key === 'Escape') {
      setInputValue(value || '');
      setIsEditing(false);
    }
  };

  return (
    <div
      className={`flex gap-[4px] p-1 items-center cursor-pointer hover:bg-mine-10 rounded-lg ${isEditing ? 'bg-mine-10' : ''
        }`}
      onClick={handleInputFocus}
    >
      <div className=''>
        {fieldKey === 'budget' ? (
          <Coins alt="budget" className="text-[#4F69B0] relative w-[24px] h-[32px]" />
        ) : fieldKey === 'extBudget' ? (
          <CoinsOut alt="extBudget" className="text-[#4F69B0] relative w-[24px] h-[32px]" />
        ) : fieldKey === 'hours' && (
          <Time alt="hours" className="text-[#4F69B0] relative w-[24px] h-[32px]" />
        )}
      </div>

      {isEditing ? (
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          className="font-medium text-gray bg-transparent outline-none"
          autoFocus
        />
      ) : (
        <div className="font-medium text-gray">
          {inputValue !== null && inputValue !== '' && inputValue !== undefined ? (
            <span>
              {inputValue}
              {suffix && ` ${suffix}`}
            </span>
          ) : (
            <span>{defaultText}</span>
          )}
        </div>
      )}
    </div>
  );
};


const ProcessManager = () => {
  const queryClient = useQueryClient(); // This hook is called at the top level of the component
  const { mutate: updateTaskStatus } = useUpdateTaskStatus({
    onSettled: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });
  const location = useLocation();
  let processID = new URLSearchParams(location.search).get('id') || '1';
  let taskID = new URLSearchParams(location.search).get('task') || null;
  const navigate = useNavigate(); // Hook for navigation
  const [selectedTask, setSelectedTask] = useState(null);
  const [showUserSelectionPopup, setShowUserSelectionPopup] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [showDeadlinePopup, setShowDeadlinePopup] = useState(false);
  // const [isStartEnabled, setIsStartEnabled] = useState(false);
  const userFieldRef = useRef(null);
  const deadlineFieldRef = useRef(null);

  const [taskContent, setTaskContent] = useState("");
  const [isContentModified, setIsContentModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditingProcess, setIsEditingProcess] = useState(false);
  const [editedProcessTitle, setEditedProcessTitle] = useState("");
  const [isEditingTask, setIsEditingTask] = useState(false);
  const [editedTaskTitle, setEditedTaskTitle] = useState('');
  const [lastSelectedTaskId, setLastSelectedTaskId] = useState(null);
  const [customFieldsState, setCustomFieldsState] = useState(selectedTask?.customFields || {});

  // Fetch tasks and ensure they are sorted
  const { data: fetchedTasks, isLoading: isTasksLoading, error: tasksError } = useGetTasks(processID);
  const tasks = React.useMemo(() => { return fetchedTasks ? fetchedTasks.sort((a, b) => a.id - b.id) : []; }, [fetchedTasks]);
  const prevTasksLengthRef = useRef(tasks.length);

  const currentUser = useGetCurUser();

  const { data: process, isLoading: isProcessLoading, error: processError } = useGetProcess(processID);
  const [fieldsSuffix, setFieldsSuffix] = useState(process?.fieldsSuffix || {});

  const { data: allUsers } = useGetUsers();
  const { mutate: createTask } = useCreateTask();
  const { mutate: createSubtask } = useCreateSubtask();

  const updateProcess = useUpdateProcess();
  const updateTaskUserAssignment = useUpdateTaskUserAssignment();
  const updateTask = useUpdateTask();
  const updateTaskTitle = useUpdateTaskTitle();
  const updateTaskMilestone = useUpdateTaskMilestone();
  const updateTaskContent = useUpdateTaskContent();
  const createNotification = useCreateNotification();
  const deleteNotification = useDeleteNotification();
  const [isNewTaskCreated, setIsNewTaskCreated] = useState({ state: false, title: '', id: null });
  const [expandedTasks, setExpandedTasks] = useState([]);
  const deleteTask = useDeleteTask();
  const inputFieldRefs = useRef({});

  // Handlers
  const toggleTaskIsMilestone = useCallback((task) => {
    if (task.parentTaskId) {
      // If the task is a subtask, do nothing
      return;
    }

    const isMilestone = !task.isMilestone;
    let updatedTitle = task.title;

    if (isMilestone) {
      // If setting the task as a milestone and the title is "New Task", update the title to "Milestone"
      if (task.title === "New Task") {
        updatedTitle = "Milestone";
      }
    } else {
      // If unsetting the milestone and the title is "Milestone", revert the title to "New Task"
      if (task.title === "Milestone") {
        updatedTitle = "New Task";
      }
    }

    const updatedAttribValue = {
      isMilestone,
      title: updatedTitle,
    };

    updateTaskMilestone.mutate({
      taskId: task.id,
      attribValue: updatedAttribValue,
    });
  }, [updateTaskMilestone]);

  const toggleTaskIsDone = useCallback((task) => {
    if (task.isWarning) {
      deleteNotification.mutate({
        taskId: task.id,
      });
    }
    updateTaskStatus({
      taskId: task.id,
      isDone: !task.isDone,
      isWarning: false,
    });
    // No direct call to setIgnoreTaskIDOnce here; ensure its management aligns with overall state logic
  }, [updateTaskStatus, deleteNotification]);

  const toggleTaskIsWarning = useCallback((task) => {
    if (!task.isWarning) {

      createNotification.mutate({
        // Assuming you add a `taskId` field or similar to reference the task
        taskId: task.id,
        processId: task.processId,
        toUuid: task.usersAssigned[0] ? task.usersAssigned[0] : currentUser?.uuid,
        byUuid: currentUser?.uuid,
        companyUuid: currentUser?.companyUuid ? currentUser?.companyUuid : currentUser?.uuid,
        message: `${task.title} needs attention!`,
        type: 'warning',
      });
    } else {
      // If we're removing a warning, delete the notification
      // This assumes you have a way to identify the specific notification to delete
      deleteNotification.mutate({
        taskId: task.id, // This will depend on how you're tracking which notification belongs to which task
      });
    }
    updateTaskStatus({
      taskId: task.id,
      isWarning: !task.isWarning,
      isDone: false,
    });
  }, [updateTaskStatus, createNotification, currentUser?.uuid, currentUser?.companyUuid, deleteNotification]);


  const handleBackClick = () => {
    if (isNewTaskCreated.state && process.fields.deadline) {
      const confirm = window.confirm(`Please set a deadline to ${isNewTaskCreated.title}!`);
      if (confirm) return;
    }
    navigate('/timeline'); // Adjust the path as needed for your timeline
  };

  const handleCreateTaskClick = () => {
    const newTaskDetails = {
      processId: processID,
      title: "New Task",
    };
    createTask(newTaskDetails, {
      onSuccess: (data) => {

        setLastSelectedTaskId(data.id);
        const newTask = data[0]; // Adjust based on your API response structure
        setIsNewTaskCreated({ state: true, title: newTask.title, id: newTask.id });
        //  // Consider setting this only if you're auto-selecting the new task
      },
      onError: (error) => {
        console.error('Error creating task:', error);
      }
    });
  };

  // const handleInputChange = (key, value) => {
  //   setCustomFieldsState((prevState) => ({
  //     ...prevState,
  //     [key]: value,
  //   }));
  // };

  // const handleInputBlur = (key, value) => {
  //   updateTask.mutate(
  //     {
  //       taskId: selectedTask.id,
  //       attribName: 'customFields',
  //       attribValue: {
  //         ...selectedTask.customFields,
  //         [key]: value === '' || value === '0' ? '' : value,
  //       },
  //     },
  //     {
  //       onSuccess: () => {
  //         // Invalidate the relevant queries to trigger a refetch
  //       },
  //       onError: (error) => {
  //         console.error('Error updating custom fields:', error);
  //       },
  //     }
  //   );
  // };


  const handleCreateSubtaskClick = (curTaskId = null) => {
    const newTaskDetails = {
      processId: processID,
      title: "New Task",
      parentTaskId: curTaskId
    };
    createSubtask(newTaskDetails, {
      onSuccess: (data) => {

        setLastSelectedTaskId(data.id);
        const newTask = data[0]; // Adjust based on your API response structure
        setIsNewTaskCreated({ state: true, title: newTask.title, id: newTask.id });
        setExpandedTasks(prev => [...new Set([...prev, curTaskId])]);
        //  // Consider setting this only if you're auto-selecting the new task
      },
      onError: (error) => {
        console.error('Error creating task:', error);
      }
    });
  };

  const handleProcessChange = (color = null) => {
    // Call the update function
    color && updateProcess.mutate(
      { processId: processID, update: { color } }, // processID should be the ID of your current process/task
      {
        onSuccess: () => {
        },
        onError: (error) => {
          console.error('Error updating color:', error);
          // Error handling logic here
        }
      }
    );
  };

  const handleProcessTitleClick = useCallback(() => {
    if (process) { // Ensure process is not undefined
      setEditedProcessTitle(process.title);
      setIsEditingProcess(true);
    }
  }, [process]);

  const handleProcessTitleChange = useCallback((e) => {
    setEditedProcessTitle(e.target.value);
  }, []);

  const handleProcessTitleSave = useCallback(() => {
    if (editedProcessTitle && process.id) {
      updateProcess.mutate({ processId: process.id, update: { title: editedProcessTitle } });
      setIsEditingProcess(false);
    }
  }, [editedProcessTitle, process?.id, updateProcess]);

  const handleTaskClick = useCallback((task) => {
    // Only update content if a different task is selected
    if (!selectedTask || task.id !== selectedTask.id) {
      setSelectedTask(task);
      setTaskContent(task.content);
      setEditedTaskTitle(task.title);
      setLastSelectedTaskId(task.id);
    }
  }, [selectedTask]);

  const handleTaskTitleClick = useCallback((task) => {
    // Only switch to edit mode if this task is already selected
    if (selectedTask && task.id === selectedTask.id) {
      setIsEditingTask(true); // Enter edit mode directly if already selected
      setEditedTaskTitle(task.title); // Set the current title in the state
    } else {
      // For any task click that's not already selected, just select the task
      setSelectedTask(task);
      setTaskContent(task.content);
      setLastSelectedTaskId(task.id);
    }
  }, [selectedTask, setEditedTaskTitle, setIsEditingTask, setSelectedTask, setTaskContent, setLastSelectedTaskId]);


  const handleTaskContentChange = useCallback((content) => {
    setTaskContent(content);
    setIsContentModified(true);

  }, []);


  const handleTaskTitleChange = useCallback((e) => {
    setEditedTaskTitle(e.target.value);
  }, []);

  const handleTaskTitleSave = useCallback((e) => {
    if (editedTaskTitle && selectedTask) {
      updateTaskTitle.mutate({ taskId: selectedTask.id, title: e.target.value });
      setIsEditingTask(false);
    }
  }, [editedTaskTitle, selectedTask, updateTaskTitle]);


  const handleUserSelect = useCallback((selectedUsers) => {
    if (selectedTask) {
      updateTaskUserAssignment.mutate(
        { taskId: selectedTask.id, usersAssigned: selectedUsers },
        {
          onError: (error) => {
            console.error('Failed to update task user assignment:', error);
            // Error handling logic here
          },
        }
      );
    }
  }, [selectedTask, updateTaskUserAssignment]);

  const handleDeadlineSelect = useCallback((newDeadline) => {
    if (selectedTask) {
      updateTask.mutate({
        taskId: selectedTask.id,
        attribName: 'deadline', // Specify the attribute name
        attribValue: newDeadline // Provide the new value
      }, {
        // Success callback
        onSuccess: () => {
          // Check if the selected task matches the newly created task
          if (selectedTask.id === isNewTaskCreated.id) {
            // Reset the isNewTaskCreated state
            setIsNewTaskCreated({ state: false, title: '', id: null });
          }
          setShowDeadlinePopup(!showDeadlinePopup);
        }
      });
    }
  }, [selectedTask, updateTask, showDeadlinePopup, isNewTaskCreated, setIsNewTaskCreated]);

  // Adjust the popup showing handlers to use the refs
  const handleUserFieldClick = useCallback((event) => {
    event.stopPropagation();
    const elementRect = userFieldRef.current.getBoundingClientRect();
    setCursorPosition({
      x: elementRect.left,
      y: elementRect.bottom // Position the popup right below the element
    });
    (showUserSelectionPopup === 2) ? setShowUserSelectionPopup(false) : setShowUserSelectionPopup(true);

  }, [showUserSelectionPopup]);

  const handleDeadlineFieldClick = useCallback((event) => {
    event.stopPropagation();
    const elementRect = deadlineFieldRef.current.getBoundingClientRect();
    setCursorPosition({
      x: elementRect.left,
      y: elementRect.bottom // Position the popup right below the element
    });
    (showDeadlinePopup === 2) ? setShowDeadlinePopup(false) : setShowDeadlinePopup(true);
  }, [showDeadlinePopup]);

  // const handleStartSelect = useCallback((newStart) => {
  //   if (selectedTask) {
  //     updateTask.mutate({
  //       taskId: selectedTask.id,
  //       attribName: 'start', // Specify the attribute name
  //       attribValue: newStart // Provide the new value
  //     });
  //     setShowDeadlinePopup(false);
  //   }
  // }, [selectedTask, updateTask]);

  const handleDeleteTask = useCallback((taskId) => {
    if (window.confirm(`Are you sure you want to delete this task?`)) {
      deleteTask.mutate({ taskId }, {
        onSuccess: () => {
          queryClient.invalidateQueries(['tasks']);

          // Wait for tasks list update after deletion
          setTimeout(() => {
            const updatedTasksList = tasks.filter(task => task.id !== taskId); // New state of tasks after deletion

            // Find the index of the deleted task in the original list for reference
            let targetIndex = tasks.findIndex(task => task.id === taskId);
            let deletedTask = tasks[targetIndex]; // The task that was deleted

            let newSelectedTask = null;

            // If deleted task was a subtask
            if (deletedTask && deletedTask.parentTaskId) {
              // Get all siblings in the updated state, including the deleted task for accurate index reference
              const allSiblingsIncludingDeleted = tasks.filter(t => t.parentTaskId === deletedTask.parentTaskId);
              // Find the index of the deleted subtask among its siblings before deletion
              const deletedSubtaskIndex = allSiblingsIncludingDeleted.findIndex(t => t.id === taskId);

              // Get updated siblings without the deleted task
              const updatedSiblings = updatedTasksList.filter(t => t.parentTaskId === deletedTask.parentTaskId);

              // If there is a previous sibling in the updated list, select it
              if (deletedSubtaskIndex > 0) {
                // Adjust index for updated siblings since one sibling (the deleted one) is missing
                newSelectedTask = updatedSiblings[deletedSubtaskIndex - 1];
              } else {
                // No previous sibling, select the parent task
                newSelectedTask = updatedTasksList.find(t => t.id === deletedTask.parentTaskId);
              }
            } else {
              // Handling the deletion of a parent task or a standalone task
              let previousTask = updatedTasksList[targetIndex - 1] || null;
              let nextTask = updatedTasksList[targetIndex] || null; // This might be undefined if the task was last in the original list

              newSelectedTask = previousTask || nextTask;
            }

            if (!newSelectedTask && updatedTasksList.length > 0) {
              // As a last resort, select the first task in the updated list
              newSelectedTask = updatedTasksList[0];
            }

            // Update state with the newly selected task
            if (newSelectedTask) {
              setSelectedTask(newSelectedTask);
              setLastSelectedTaskId(newSelectedTask.id);
            } else {
              setSelectedTask(null);
              setLastSelectedTaskId(null);
            }
          }, 0);



        },
        onError: (error) => {
          console.error("Failed to delete task: ", error.message);
        }
      });
    }
  }, [deleteTask, tasks, queryClient, setSelectedTask, setLastSelectedTaskId]);


  // Save task content with debounce
  useEffect(() => {
    // Setup a timer to handle the debounce logic
    const debounceSaveTimer = setTimeout(() => {
      if (isContentModified && selectedTask) {
        // Indicate saving is starting
        setIsSaving(true);

        // Simulate the save operation with a delay
        updateTaskContent.mutate({
          taskId: selectedTask.id,
          content: taskContent,
        }, {
          onSuccess: () => {
            setIsContentModified(false);

            // This is where the save operation is considered 'complete'
            // But we still show the saving indicator for an additional period
            // to maintain the user feedback
            setTimeout(() => setIsSaving(false), 1500);
          },
          // Assuming an onError handler for completeness
          onError: () => {
            // Handle save operation error
            setIsSaving(false);
          }
        });
      }
    }, 1500); // Debounce period before initiating save

    return () => clearTimeout(debounceSaveTimer);
  }, [taskContent, isContentModified, selectedTask, updateTaskContent]);


  // Effect for handling initial selection based on taskID
  useEffect(() => {
    let taskToSelect;

    // Filter tasks to include only parent tasks
    const parentTasks = tasks.filter(task => !task.parentTaskId);

    if (taskID) {
      // If there's a specific taskID, find it in the filtered parentTasks
      taskToSelect = parentTasks.find(task => task.id === parseInt(taskID));
    } else {
      // Otherwise, select the last parent task by default
      taskToSelect = parentTasks.length > 0 ? parentTasks[parentTasks.length - 1] : null;
    }

    if (taskToSelect) {
      setSelectedTask(taskToSelect);
      setTaskContent(taskToSelect.content || '');
    }
  }, [tasks, taskID]); // Depend on taskID and tasks


  // Effect for updating selection when tasks list changes due to addition or deletion
  useEffect(() => {
    const currentTasksLength = tasks.length;
    const prevTasksLength = prevTasksLengthRef.current;
    prevTasksLengthRef.current = currentTasksLength; // Update the ref to the current length for the next render

    // Check if a task was specifically added (length increased) or removed (length decreased)
    if (currentTasksLength > prevTasksLength || currentTasksLength < prevTasksLength) {
      const taskToSelect = tasks[tasks.length - 1]; // Select the last task
      setSelectedTask(taskToSelect);
      setTaskContent(taskToSelect?.content || '');
    }
  }, [tasks]);
  // Note: Adjust or remove the initialSelectionDone state as necessary based on your exact requirements.

  useEffect(() => {
    if (selectedTask) {
      setCustomFieldsState(selectedTask.customFields || {});
      setFieldsSuffix(process?.fieldsSuffix || {});
    }
  }, [selectedTask, process]);

  useEffect(() => {
    if (lastSelectedTaskId && tasks) {
      const newSelectedTask = tasks.find(task => task.id === lastSelectedTaskId);
      setSelectedTask(newSelectedTask);
      setTaskContent(newSelectedTask.content || '');

      // Update the input field's value using the stored reference
      Object.keys(newSelectedTask.customFields || {}).forEach((key) => {
        const inputField = inputFieldRefs.current[key];
        let textField;

        if (inputField) {
          const parentDiv = inputField.parentNode;
          textField = parentDiv ? parentDiv.querySelector('.text-gray') : null;
        }

        if (inputField && textField) {
          const value = newSelectedTask.customFields[key];

          if (value !== undefined && value !== null && value !== '') {
            inputField.value = value;
            textField.textContent = `${value}${fieldsSuffix[key] ? ` ${fieldsSuffix[key]}` : ''}`;
          } else {
            inputField.value = '';
            textField.textContent = key === 'budget'
              ? 'Select budget'
              : key === 'extBudget'
                ? 'Select external'
                : 'Select est. time';
          }
        }
      });
    }
  }, [tasks, lastSelectedTaskId, fieldsSuffix]);

  if (isProcessLoading || isTasksLoading || !allUsers) {
    return <div></div>;
  }

  if (processError || tasksError) {
    return <div>Error: {processError?.message || tasksError?.message}</div>;
  }
  return (
    <div className=" w-full">
      {process && (
        <>
          {/* Header and Process Title */}
          <header className="text-left ml-6">
            <div className='flex'>
              <div
                onClick={handleBackClick}
                className="cursor-pointer flex mr-4"
                style={{}}
              >
                <Left className="m-auto special-text " style={{ width: '40px', height: '31px' }} />

              </div>

              {isEditingProcess ? (
                <input
                  type="text"
                  value={editedProcessTitle}
                  onChange={handleProcessTitleChange}
                  onBlur={handleProcessTitleSave}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Escape') {
                      handleProcessTitleSave();
                      e.preventDefault();
                    }
                  }}
                  className="text-4xl font-extrabold special-text"
                  autoFocus />
              ) : (
                <h1 onClick={handleProcessTitleClick} className="text-4xl font-extrabold special-text cursor-pointer inline">
                  {process.title === 'New Process' ?
                    (<div className='flex items-center gap-1'>
                      <div>{process.title}</div>
                      <Pen alt="Pen" className='h-[28px] flex-shrink-0 ' />
                    </div>)
                    : <>{process.title}</>}
                </h1>
              )}
              <button
                onClick={() => navigate('/taskview')}
                className="bg-mine hover:bg-mine-700 text-white font-bold py-2 px-4 
                rounded-full flex items-center space-x-2 force-shadow-lg h-[50px] ml-auto mr-64"
              >
                <User alt='user' className="text-white relative w-[24px] left-[1px] flex-shrink-0" />

                <span>TaskView</span>
              </button>
            </div>
            <p className="text-md text-gray-500 ml-14">Status</p>
          </header>

          {/* Task List and Editor */}
          <ErrorBoundary>

            <div className='flex flex-col gap-6 lg:flex-row-reverse justify-center'>
              {/* Task Fields and User Assignment */}
              <div className='flex gap-6 flex-row lg:flex-col justify-start '>
                {selectedTask && (
                  <>
                    {Object.keys(process.fields).some(key => key !== 'subTasks' && key !== 'milestone' && process.fields[key]) && (
                      <Card title="Fields">
                        {process.fields.userAssign && (
                          <div
                            ref={userFieldRef}
                            className='flex gap-1 p-1 items-center cursor-pointer mt-2 hover:bg-mine-10 rounded-lg'
                            onClick={handleUserFieldClick}
                          >
                            <User alt='user' className="text-[#4F69B0] relative w-[24px] left-[1px] flex-shrink-0" />
                            <div className='font-medium text-gray truncate cursor-pointer'>
                              {selectedTask.usersAssigned.length > 0 ? (
                                selectedTask.usersAssigned.map((userUuid, index) => {
                                  const user = allUsers.find(u => u.uuid === userUuid);
                                  return user && (
                                    <span key={userUuid}>
                                      {user.name}{index < selectedTask.usersAssigned.length - 1 ? ', ' : ''}
                                    </span>
                                  );
                                })
                              ) : (
                                <span>Select Users</span> // Display this text when no users are assigned
                              )}
                            </div>
                          </div>
                        )}
                        {process.fields.deadline && (
                          <div
                            ref={deadlineFieldRef}
                            className='flex gap-[4px] p-1 items-center cursor-pointer hover:bg-mine-10 rounded-lg'
                            onClick={handleDeadlineFieldClick}
                          >
                            <DeadLine alt='deadline' className="text-[#4F69B0] relative w-[24px] left-[1px] flex-shrink-0" />
                            <div className='font-medium text-gray'>{selectedTask.deadline ? formatDate(selectedTask.deadline) : 'Select Deadline'}</div>
                          </div>
                        )}
                        {process && process.fields && (
                          <>
                            <div className="">
                              {Object.entries(process.fields)
                                .filter(([key]) => ['budget', 'extBudget', 'hours'].includes(key))
                                .map(([key, value]) => {
                                  // const taskValue = selectedTask.customFields && selectedTask.customFields[key] !== undefined ? selectedTask.customFields[key] : "";
                                  if (value) {
                                    return (
                                      <CustomField
                                        key={key}
                                        fieldKey={key}
                                        value={customFieldsState[key]}
                                        suffix={fieldsSuffix[key]}
                                        defaultText={
                                          key === 'budget'
                                            ? 'Select budget'
                                            : key === 'extBudget'
                                              ? 'Select external'
                                              : 'Select est. hours'
                                        }
                                        onValueChange={(fieldKey, newValue) => {
                                          setCustomFieldsState((prevState) => ({
                                            ...prevState,
                                            [fieldKey]: newValue,
                                          }));
                                          updateTask.mutate(
                                            {
                                              taskId: selectedTask.id,
                                              attribName: 'customFields',
                                              attribValue: {
                                                ...selectedTask.customFields,
                                                [fieldKey]: newValue === '' || newValue === '0' ? '' : newValue,
                                              },
                                            },
                                            {
                                              onSuccess: () => {
                                                // Invalidate the relevant queries to trigger a refetch
                                              },
                                              onError: (error) => {
                                                console.error('Error updating custom fields:', error);
                                              },
                                            }
                                          );
                                        }}
                                      />
                                    );
                                  }
                                  return null;
                                })}
                            </div>
                          </>
                        )}

                      </Card>
                    )}
                    <Card className={`${!Object.keys(process.fields).some(key => key !== 'subTasks' && key !== 'milestone' && process.fields[key]) && 'mt-[132px]'}`} title="Status">
                      <div
                        className='flex gap-1 p-2 items-center font-medium text-gray w-full cursor-pointer hover:bg-mine-10 rounded-lg'
                        onClick={() => toggleTaskIsDone(selectedTask)}
                      >
                        {selectedTask.isDone ? (<div className='flex items-center gap-1.5'>
                          <CheckIcon className="task-icon" />Done</div>) : 'Finish Task'}
                      </div>
                      <div
                        className='flex gap-1 p-2 items-center font-medium text-gray w-full cursor-pointer hover:bg-mine-10 rounded-lg'
                        onClick={() => toggleTaskIsWarning(selectedTask)}
                      >
                        {selectedTask.isWarning ? (<div className='flex items-center gap-1.5'>
                          <WarningIcon className="task-icon" />Warning</div>) : 'Set Warning'}
                      </div>
                      {!selectedTask.parentTaskId && process.fields.milestone && (
                        <div
                          className='flex gap-1 p-2 items-center font-medium text-gray w-full cursor-pointer hover:bg-mine-10 rounded-lg'
                          onClick={() => toggleTaskIsMilestone(selectedTask)}
                        >
                          {selectedTask.isMilestone ? (
                            <div className='flex items-center gap-1.5'>
                              <Star className="task-icon" />Milestone
                            </div>
                          ) : 'Set Milestone'}
                        </div>
                      )}
                    </Card>

                    <Card className='' title="Modify">
                      <div className='z-10 flex flex-col mt-3 gap-1'>
                        {process.fields.subTasks && (<div
                          className='flex gap-1 p-2 items-center font-medium text-gray w-full cursor-pointer hover:bg-mine-10 rounded-lg'
                          onClick={() => handleCreateSubtaskClick(selectedTask.parentTaskId ? selectedTask.parentTaskId : selectedTask.id)}                      >
                          {'New Subtask'}
                        </div>)}
                        <div
                          className='flex gap-1 p-2 items-center font-medium text-gray w-full cursor-pointer hover:bg-mine-10 rounded-lg'
                          onClick={() => handleDeleteTask(selectedTask.id)}
                        >
                          {'Delete Task'}
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </div>

              <div className='flex flex-col w-100 items-center'>
                <div className="flex flex-wrap gap-4 mt-10 ">
                  <div className=''>
                    <div className='flex items-center'>
                      <ProgressBar
                        process={process}
                        startTime={formatDate(process.startTime)}
                        endTime={formatDate(process.deadlineTime)}
                        onClose={handleProcessChange}
                      />
                    </div>
                  </div>
                </div>


                <div className='flex mt-10 gap-6 items-center lg:items-stretch'>
                  <div className='flex bg-white p-6 rounded-xl min-h-[270px] max-w-3xl shadow-lg'>
                    {(
                      <>
                        <TaskList
                          tasks={tasks}
                          onTaskClick={handleTaskClick}
                          selectedTaskId={selectedTask ? selectedTask.id : null}
                          onTitleClick={handleTaskTitleClick}
                          isEditing={isEditingTask}
                          defaultTitle={editedTaskTitle}
                          onTitleChange={handleTaskTitleChange}
                          onTitleBlur={handleTaskTitleSave}
                          users={allUsers}
                          onCreateTask={handleCreateTaskClick}
                          expandedTasks={expandedTasks}
                          process={process}
                        />

                        <div className="relative flex-grow pl-6">
                          <QuillEditor
                            value={taskContent}
                            onChange={handleTaskContentChange}
                          />
                          {isSaving && (
                            <div className="absolute right-0 bottom-0 mb-2 ml-2 flex items-center bg-white rounded-xl ">
                              <div className="h-4 w-4 border-2 border-gray-300 border-t-transparent border-solid rounded-full animate-spin"></div>
                              <span className="ml-2 text-sm text-gray-300 font-medium">Saving...</span>
                            </div>
                          )}

                        </div>
                      </>
                    )}
                  </div>

                </div>
              </div>
            </div>

          </ErrorBoundary>

          {/* Popups for User Selection and Date Picking */}
          <UserSelectionPopup
            visible={showUserSelectionPopup}
            cursorPosition={cursorPosition}
            selectedUsers={selectedTask?.usersAssigned || []}
            users={allUsers}
            onUserSelect={handleUserSelect}
            toggleRef={userFieldRef}
            onClickOut={() => setShowUserSelectionPopup(false)}
            onClickBut={() => setShowUserSelectionPopup(2)}

          />

          <DatePickerPopup
            visible={showDeadlinePopup}
            deadline={selectedTask?.deadline ? new Date(selectedTask.deadline) : new Date()}
            onDeadlineSelect={handleDeadlineSelect}
            onClickOut={() => setShowDeadlinePopup(false)}
            onClickBut={() => setShowDeadlinePopup(2)}
            cursorPosition={cursorPosition}
            toggleRef={deadlineFieldRef}
          />
        </>
      )}
    </div>
  );

};

export default ProcessManager;
