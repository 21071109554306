import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { useUpdateProcess, useResetProcess, useGetTasks } from '../Database';
import { ReactComponent as WarningIcon } from '../icons/warning.svg';
import { ReactComponent as CheckIcon } from '../icons/check.svg';

const Infobox = ({ process, onProcessDelete }) => {
  const { data: tasks, isLoading: isLoadingTasks } = useGetTasks(process.id);
  const [showMenu, setShowMenu] = useState(false);
  const formatTime = d3.timeFormat('%d %b');
  const menuRef = useRef();
  const butRef = useRef();

  const updateProcess = useUpdateProcess();
  const resetProcess = useResetProcess();

  const toggleMenu = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  // Make sure this z-index is higher than the z-index of any siblings or other components that it should appear on top of
  const menuZIndex = 100;


  const handleDelete = () => {
    onProcessDelete(process.id);
  };

  const handleTogglePause = (process) => {
    updateProcess.mutate({ processId: process.id, update: { isPaused: !process.isPaused } }, {
      onSuccess: () => {
      },
      onError: (error) => {
        console.error('Failed to toggle pause state:', error.message);
      }
    });
  };

  const handleResetProcess = (processId) => {
    resetProcess.mutate({ processId }, {
      onSuccess: () => {
      },
      onError: (error) => {
        console.error(`Failed to reset process ID ${processId}: `, error.message);
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (butRef.current && butRef.current.contains(event.target)) {
        return;
      }
      else if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }

    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="shadow-lg flex bg-white p-2 rounded-full relative">
      <div
        className={`absolute inset-0 rounded-full ${tasks?.some(task => task.isWarning) ? 'border-2 border-red-500' : 'border-2 border-transparent'
          }`}
      />

      <div className="flex items-center">
        <div
          className="rounded-full w-[2.7rem] h-[2.7rem] ml-1 mr-3 flex font-bold items-center justify-center flex-shrink-0 text-white"
          style={{ background: `${process.color}` }}
        >
          {process.percentageComplete}%
        </div>
      </div>

      <div className="flex flex-col flex-shrink-0 text-mine justify-center">
        <div className="font-bold flex items-center">
          {process.title}
          {isLoadingTasks ? null : tasks?.some(task => task.isWarning) ? (<WarningIcon className="ml-2 text-red-500" />) : null}
          {isLoadingTasks ? null : tasks?.every(task => task.isDone) ? (<CheckIcon className="ml-2 text-red-500" />) : null}
        </div>
        <div>
          {formatTime(new Date(process.startTime))}{' '}
          {process.deadlineTime ? `- ${formatTime(new Date(process.deadlineTime))}` : ''}
        </div>
      </div>

      <div className="w-full flex items-center justify-end z-10">
        {isLoadingTasks ? (
          // Render a loading indicator or placeholder while loading
          <div></div>
        ) : null}

        {/* Kebab Menu Button */}
        <button
          ref={butRef}
          onClick={toggleMenu}
          className="text-mine font-black"
          style={{ borderRadius: '50%', width: '30px', height: '30px' }}
        >
          &#x22EE;
        </button>
      </div>
      {/* Menu */}
      {showMenu && (
        <div className='justify-end'>
          <div className='absolute w-40' ref={menuRef} >
            <div className={`z-[${menuZIndex}] flex flex-col bg-white shadow-lg p-2 rounded-xl`}
              style={{ boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', transform: 'translate(-100%,30%)' }}>
              <div
                className='w-full text-left px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium'
                onClick={(e) => { toggleMenu(e); handleTogglePause(process) }}>
                {process.isPaused === true ? 'Unpause' : 'Pause'}
              </div>
              <div
                className='w-full text-left px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium'
                onClick={(e) => { toggleMenu(e); handleResetProcess(process.id) }}>
                Reset
              </div>
              <div
                className='w-full text-left px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium'
                onClick={(e) => { toggleMenu(e); handleDelete(process.id) }}>
                Delete
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Infobox;
