import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import GanttChartReact from './comp/GanttChartReact';
import TripleToggleSwitch from './comp/triptog/TripleToggleSwitch';
import { useGetProcesses, useCreateProcess, useGetCurUser, useDeleteProcess } from './Database';
import ErrorBoundary from './comp/ErrorBoundary';

const calculateDatesAndZoom = (processes, timeScale) => {
    if (!processes || processes.length === 0) return {};

    const processStartDates = processes.map(p => new Date(p.startTime));
    const processEndDates = processes.map(p => new Date(p.deadlineTime));

    const minStartDate = new Date(Math.min(...processStartDates));
    const maxEndDate = new Date(Math.max(...processEndDates));

    let minTimeRangeThreshold;

    switch (timeScale) {
        case 'left':
            minTimeRangeThreshold = 9;
            break;
        case 'center':
            minTimeRangeThreshold = 45;
            break;
        case 'right':
            minTimeRangeThreshold = 365;
            break;
        default:
            minTimeRangeThreshold = 9;
    }

    const actualDataRangeDays = (maxEndDate.getTime() - minStartDate.getTime()) / (1000 * 60 * 60 * 24);

    let newStartDate = minStartDate;
    let newEndDate = maxEndDate;

    if (actualDataRangeDays < minTimeRangeThreshold) {
        newStartDate = new Date(minStartDate.getTime() - ((minTimeRangeThreshold - actualDataRangeDays) / 2) * (1000 * 60 * 60 * 24));
        newEndDate = new Date(newStartDate.getTime() + minTimeRangeThreshold * (1000 * 60 * 60 * 24));
    }

    const timeRange = newEndDate.getTime() - newStartDate.getTime();

    let targetZoom = timeRange / (1000 * 60 * 60 * 24);

    if (timeScale === 'left') {
        targetZoom *= 160;
    } else if (timeScale === 'center') {
        targetZoom *= 28;
    } else if (timeScale === 'right') {
        targetZoom *= 5;
    }
    return { startDate: newStartDate, endDate: newEndDate, targetZoom };
};

const Timeline = () => {
    const navigate = useNavigate();
    const [timeScale, setTimeScale] = useState('Week');
    const { data: processes, isLoading, isError, error, isSuccess } = useGetProcesses();
    const { mutate: createProcess } = useCreateProcess();
    const userData = useGetCurUser();
    const deleteProcess = useDeleteProcess();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [targetZoom, setTargetZoom] = useState(null);

    const memoizedValues = useMemo(() => {
        if (processes) {
            return calculateDatesAndZoom(processes, timeScale);
        }
        return { startDate: null, endDate: null, targetZoom: null };
    }, [processes, timeScale]);

    useEffect(() => {
        if (isSuccess) {
            setTimeScale('left');
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccess) {
            setStartDate(memoizedValues.startDate);
            setEndDate(memoizedValues.endDate);
            setTargetZoom(memoizedValues.targetZoom);
        }
    }, [isSuccess, memoizedValues]);

    const handleCreateProcess = () => {
        createProcess({ companyUuid: userData.companyUuid, managersAssigned: userData.uuid, fields: { 'userAssign': userData.type === 'user' ? 'false' : 'true' } }, {
            onSuccess: (data) => {
                navigate(`/process?id=${data[0].id}`);
            },
            onError: (error) => {
                console.error('Error creating process:', error);
            }
        });
    };

    const handleDelete = (processId) => {
        if (window.confirm("Are you sure you want to delete this process and all its tasks?")) {
            deleteProcess.mutate({ processId }, {
                onSuccess: () => {
                },
                onError: (error) => {
                    console.error(`Failed to delete process ID ${processId}: `, error.message);
                },
            });
        }
    };

    const handleTimeScaleChange = (newTimeScale) => {
        setTimeScale(newTimeScale);
    };

    if (isLoading && !processes) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;

    return (
        processes && (
            <div className="container-lg lg:container w-full">
                <header className="text-left ml-6 pl-3 lg:pl-[0px]">
                    <h1 className="text-4xl font-extrabold special-text">Overview</h1>
                    <p className="text-md text-gray-500 opacity-0">Division / Department / Team</p>
                </header>

                <div className='max-w-[1150px] m-auto overflow-hidden lg:overflow-visible'>
                    <div className="flex justify-end gap-4 mb-5 pr-[35px] lg:pr-[45px]">
                        <button
                            onClick={handleCreateProcess}
                            className="bg-mine hover:bg-mine-700 text-white font-bold py-2 px-4 rounded-full flex items-center space-x-2 force-shadow-lg"
                        >
                            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                <path d="M28 16.5C28 16.7652 27.8946 17.0196 27.7071 17.2071C27.5196 17.3946 27.2652 17.5 27 17.5H17V27.5C17 27.7652 16.8946 28.0196 16.7071 28.2071C16.5196 28.3946 16.2652 28.5 16 28.5C15.7348 28.5 15.4804 28.3946 15.2929 28.2071C15.1054 28.0196 15 27.7652 15 27.5V17.5H5C4.73478 17.5 4.48043 17.3946 4.29289 17.2071C4.10536 17.0196 4 16.7652 4 16.5C4 16.2348 4.10536 15.9804 4.29289 15.7929C4.48043 15.6054 4.73478 15.5 5 15.5H15V5.5C15 5.23478 15.1054 4.98043 15.2929 4.79289C15.4804 4.60536 15.7348 4.5 16 4.5C16.2652 4.5 16.5196 4.60536 16.7071 4.79289C16.8946 4.98043 17 5.23478 17 5.5V15.5H27C27.2652 15.5 27.5196 15.6054 27.7071 15.7929C27.8946 15.9804 28 16.2348 28 16.5Z" />
                            </svg>
                            <span>New Process</span>
                        </button>

                        <TripleToggleSwitch activeState={timeScale} onChange={handleTimeScaleChange} />
                    </div>
                    <ErrorBoundary>
                        {processes && processes.length > 0 ? (
                            <GanttChartReact
                                processData={processes}
                                startDate={startDate}
                                endDate={endDate}
                                onProcessClick={(processId) => navigate(`/process?id=${processId}`)}
                                targetZoom={targetZoom}
                                timeScale={timeScale}
                                onProcessDelete={handleDelete}
                            />
                        ) : (
                            <h4 className='flex items-center justify-center h-96 text-center text-gray-400 font-bold'>
                                No Processes. <br />Why don't you add one?
                            </h4>
                        )}
                    </ErrorBoundary>
                </div>
            </div>
        )
    );
};

export default Timeline;