import React, { useMemo } from 'react';
import ProcessPopup from './ProcessPopup';
import { useCalculateProcessCustomFields } from '../Database';
import { ReactComponent as Time } from '../icons/Clock.svg';
import { ReactComponent as Coins } from '../icons/coins.svg';
import { ReactComponent as CoinsOut } from '../icons/coinsOut.svg';

const ProgressBar = ({ startTime, process, endTime, height = 52, onClose }) => {
  let color = '#2563EB';
  if (process.color) color = process.color;

  const { data: customFieldsData, isLoading, error } = useCalculateProcessCustomFields(process);

  const totalWidth = 100; // Assuming the width is 100% for the entire bar
  const completedWidth = totalWidth * (-1 * (1 - process.percentageComplete / 100));

  const memoizedCustomFields = useMemo(() => {
    if (isLoading) {
      return <div>Loading custom fields...</div>;
    }

    if (error) {
      return <div>Error loading custom fields: {error.message}</div>;
    }

    return Object.entries(customFieldsData).map(([fieldKey, fieldValue]) => {
      if (fieldKey !== 'budget' && fieldKey !== 'extBudget' && fieldKey !== 'hours') {
        return null; // Don't render anything for keys other than 'budget', 'extBudget', and 'hours'
      }

      return (
        <div key={fieldKey} className="flex items-center font-extrabold special-text">
          <div className=''>
            {fieldKey === 'budget' ? (
              <Coins alt="budget" className="text-[#4F69B0] relative w-[24px] h-[24px]" />
            ) : fieldKey === 'extBudget' ? (
              <CoinsOut alt="extBudget" className="text-[#4F69B0] relative w-[24px] h-[24px]" />
            ) : fieldKey === 'hours' && (
              <Time alt="hours" className="text-[#4F69B0] relative w-[24px] h-[24px]" />
            )}
          </div>
          <div className="ml-2">
            {fieldValue.done} / {fieldValue.total} {process.fieldsSuffix && process.fieldsSuffix[fieldKey]}
          </div>
        </div>
      );
    });
  }, [customFieldsData, isLoading, error, process.fieldsSuffix]);

  return (
    <div className='flex items-center justify-center w-full'>
      <div className='flex flex-col flex-shrink-0 mr-3 items-center'>
        <h2 className="font-extrabold special-text">{startTime}</h2>
        {endTime !== 'Infinity' &&
          (<>
            <div className="h-[3px] bg-gray-100 w-5 rounded mx-auto"></div>
            <h2 className="font-extrabold special-text">{endTime}</h2>
          </>)}
      </div>

      <div
        className="flex items-center justify-start w-full bg-opacity-20 rounded-full min-w-[350px] overflow-hidden"
        style={{ backgroundColor: `${color}20`, height: `${height}px` }}
      >
        <div
          className="flex items-center justify-end w-full text-white font-bold rounded-full"
          style={{
            width: `${totalWidth}%`,
            backgroundColor: color,
            height: `${height}px`,
            left: `${completedWidth}%`,
            position: 'relative',
          }}
        >
          <span
            style={{ display: `${process.percentageComplete < 40 ? "none" : "block"}` }}
            className={`pr-3`}
          >
            {process.percentageComplete}%
          </span>
        </div>
      </div>

      <div className={`items-center justify-end flex z-10`}>
        <div className={`h-[40px] w-[40px] mr-2 absolute`}>
          <ProcessPopup process={process} currentColor={color} onClose={onClose} />
        </div>
      </div>

      <div className='flex flex-col flex-shrink-0 ml-3'>
        {memoizedCustomFields}
      </div>
    </div>
  );
};

export default ProgressBar;
