import React, { useRef } from 'react';
import * as d3 from 'd3';

const TimeAxis = ({ scales, height, margin, currentDate, dayInterval, }) => {
  const ref = useRef(null);
  const isYearMode = dayInterval > 28;
  const gridLines = getGridLines(scales, height, isYearMode, dayInterval); // Pass isYearMode if needed
  const currentDayXPosition = scales.xScale(currentDate);

  const yearFormat = d3.timeFormat('%Y'); // Define year format

  // Styles for the grid lines and text
  const gridLineStyle = (x) => ({
    position: 'absolute',
    left: `${x}px`,
    top: `${margin.top + 10}px`,
    bottom: `${margin.bottom}px`,
    borderLeft: '1px solid #ccc'
  });

  const textStyle = (x, y = 0, color = '#253766') => ({
    position: 'absolute',
    left: `${x}px`,
    top: `${margin.top - 40 + y}px`, // Adjust as needed
    color,
    fontSize: '12px',
    fontWeight: '600',
    transform: 'translateX(-50%)'
  });

  const containerWidth = scales.xScale.range()[1] - scales.xScale.range()[0];
  return (
    <div ref={ref} className='relative ' style={{ width: `${containerWidth}px`, height: `${height}px` }}>
      <CurrentDayMarker
        className='curDay'
        xPosition={currentDayXPosition}
        height={height}
        margin={margin}
      />
      <div className='bg-white w-full h-full z-[-10] rounded-3xl'>
        {gridLines.map((line) => (
          <React.Fragment key={line.key}>
            <div style={gridLineStyle(line.x1)}></div>
            {line.isFirstDayOfMonth && (
              <>
                <div style={textStyle(line.x1, isYearMode && 20, !isYearMode ? '#7F8FA6' : '#253766')}>{line.month}</div>
                {/* Check if it's January in Year mode to display the year */}
                {isYearMode && line.month === 'Jan' && (
                  <div style={textStyle(line.x1, 0, '#7F8FA6')}>{yearFormat(scales.xScale.domain()[0])}</div>
                )}
              </>
            )}
            {!isYearMode && (
              <div style={textStyle(line.x1, 18)}>{line.day}</div>
            )}
          </React.Fragment>
        ))}
      </div>

    </div>
  );
};

export default TimeAxis;


export const CurrentDayMarker = ({ xPosition, height }) => {
  // Calculate styles based on props
  const lineStyle = {
    position: 'absolute',
    left: `${xPosition}px`,
    width: '1.5px', // This represents the stroke width
    height: `100%`,
    backgroundColor: '#25376670',
    transform: "translateX(-50%)"
  };

  const squareStyle = {
    position: 'absolute',
    top: '0px',
    left: `${xPosition}px`,
    width: '14px',
    height: '14px',
    backgroundColor: '#253766',
    transform: "translateX(-50%)"
  };

  const rotatedSquareStyle = {
    position: 'absolute',
    top: '9px',
    left: `${xPosition}px`,
    width: '9.9px',
    height: '9.9px',
    backgroundColor: '#253766',
    transform: 'translateX(-50%) rotate(45deg)',
  };

  // Outer container that holds the position of the marker
  const containerStyle = {
    position: 'absolute',
    height: `${height}px`,
    zIndex: 11
  };

  return (
    <div className='curCool' style={containerStyle}>
      <div style={lineStyle}></div>
      <div style={squareStyle}></div>
      <div style={rotatedSquareStyle}></div>
    </div>
  );
};


const getGridLines = (scales, height, isYearMode, dayInterval = 30) => {
  const dayFormat = d3.timeFormat('%d');
  const monthFormat = d3.timeFormat('%b');
  const gridLines = [];
  let lastSeenMonth = null;
  const timeSpan = (scales.xScale.domain()[1] - scales.xScale.domain()[0]) / (1000 * 60 * 60 * 24);

  if (isYearMode) {
    scales.xScale.ticks(d3.timeMonth.every(1)).forEach((d, i) => {
      const month = monthFormat(d);
      let isFirstDayOfMonth = lastSeenMonth !== month;

      gridLines.push({
        key: `month-line-${i}`,
        x1: scales.xScale(d),
        y1: 0,
        x2: scales.xScale(d),
        y2: height,
        isFirstDayOfMonth,
        month
      });
    });
  } else {
    // Use custom interval if provided, otherwise adjust interval to limit to 20 lines
    let intervals = [1, 5, 13, 30]; // Daily, Weekly, Biweekly, Monthly
    let chosenInterval = intervals.find(interval => Math.ceil(timeSpan / interval) <= 20);
    chosenInterval = chosenInterval || 30; // Default to 30 if no suitable interval found

    if (dayInterval !== null) chosenInterval = dayInterval; // Override with custom interval

    scales.xScale.ticks(d3.timeDay.every(1)).forEach((d, i) => {
      const day = dayFormat(d);
      const month = monthFormat(d);
      let isFirstDayOfMonth = lastSeenMonth !== month;
      if (i % chosenInterval === 0) {
        gridLines.push({
          key: `line-${i}`,
          x1: scales.xScale(d),
          y1: 0,
          x2: scales.xScale(d),
          y2: height,
          day,
          isFirstDayOfMonth,
          month
        });

        if (isFirstDayOfMonth) lastSeenMonth = month;
      }
    });
  }

  return gridLines;
};
