import React, { useRef, useEffect, useState } from 'react';
import UserCircles from '../comp/UserCircles'; // Adjust the path as necessary
import { ReactComponent as CheckIcon } from '../icons/check.svg'; // Adjust the path
import { ReactComponent as CheckGIcon } from '../icons/checkG.svg'; // Adjust the path
import { ReactComponent as WarningIcon } from '../icons/warning.svg'; // Adjust the path
import { ReactComponent as Star } from '../icons/star.svg'; // Adjust the path
import { useGetUsers, useGetTasks } from '../Database'; // Adjust the import path
import { ReactComponent as Pen } from '../icons/penBold.svg'; // Adjust the path
import { ReactComponent as CaretR } from '../icons/caretR.svg'; // Adjust the path

export const ReadOnlyTaskList = ({ id }) => {
    const { data: users, isLoading: isLoadingUsers, isError: isErrorUsers, error: errorUsers } = useGetUsers();
    const { data: tasks, isLoading: isLoadingTasks, isError: isErrorTasks, error: errorTasks } = useGetTasks(id);

    if (isLoadingUsers || isLoadingTasks) return <div></div>;
    if (isErrorUsers || isErrorTasks) return <div>Error: {errorUsers?.message || ''} {errorTasks?.message}</div>;
    if (tasks.length === 0)
        return <h4 className='text-center text-gray-400 font-bold'>
            No tasks available
        </h4>;
    let sortedTasks = [...tasks].sort((a, b) => a.position - b.position);
    const lastCheckedTask = sortedTasks.reduce((last, task) => task.isDone ? task : last, null);

    let tasksToShow;
    if (lastCheckedTask) {
        const lastCheckedTaskIndex = sortedTasks.findIndex(task => task.id === lastCheckedTask.id);
        const startIndex = Math.max(0, lastCheckedTaskIndex - 1);
        const endIndex = Math.min(sortedTasks.length, lastCheckedTaskIndex + 2);
        tasksToShow = sortedTasks.slice(startIndex, endIndex);
    } else {
        tasksToShow = sortedTasks.slice(-3);
    }

    const findUserByUuid = userId => users.find(user => user.uuid === userId);

    return (
        <div className="flex flex-col space-y-2" style={{ width: '200px' }}>
            {tasksToShow.map(task => (
                <div key={task.id} className={`flex task-item text-mine-200 items-center px-3 py-2 bg-white shadow rounded-lg ${lastCheckedTask && lastCheckedTask.id === task.id ? 'task-item-selected' : ''}`}>
                    {task.usersAssigned && task.usersAssigned.length > 0 && (
                        <UserCircles users={task.usersAssigned.map(findUserByUuid)} />
                    )}
                    <div className="ml-1.5 flex-1 min-w-0 font-medium m-1 truncate">
                        <span style={{ fontSize: '14px' }}>{task.title}</span>
                    </div>
                    {task.isMilestone && (
                        <Star className={`ml-2 task-icon ${task.isDone ? 'text-green-500' : ''}`} />
                    )}
                    {task.isDone && !task.isWarning && (
                        <CheckIcon className="ml-2 h-5 w-5 text-green-500" />
                    )}
                    {task.isWarning && (
                        <WarningIcon className="ml-2 h-5 w-5 text-red-500" />
                    )}
                </div>
            ))}
        </div>
    );
};

const Task = ({ task, subtasks = [], selectedTaskId, onTaskClick, isEditing, onTitleClick, onTitleChange,
    expandedTasks, onTitleBlur, handleKeyPress, defaultTitle, findUserByuuId, process }) => {
    const [isOpen, setIsOpen] = useState(expandedTasks.includes(selectedTaskId));
    const isSelected = task.id === selectedTaskId;
    const [editingTaskId, setEditingTaskId] = useState(null);

    const doneSubtasksCount = subtasks.filter(subtask => subtask.isDone).length;
    const warningSubtasks = subtasks.filter(subtask => subtask.isWarning).length > 0;

    const areAllSubtasksDone = doneSubtasksCount === subtasks.length && subtasks.length > 0;

    useEffect(() => {
        // Automatically open the task if it's marked as expanded
        process.fields.subTasks ? setIsOpen(expandedTasks.includes(task.id)) : setIsOpen(false);
    }, [expandedTasks, task.id, process.fields.subTasks]);

    const handleTaskClick = (e, taskItem) => {
        e.stopPropagation(); // Prevent event bubbling
        onTaskClick(taskItem);
    };

    const handleTitleClick = (e, taskItem) => {
        e.stopPropagation(); // Prevent event bubbling
        setEditingTaskId(taskItem.id);
        onTitleClick(taskItem);
    };

    return (
        <div>
            {/* Parent Task */}
            <div
                onClick={(e) => { handleTaskClick(e, task); }}
                className={`flex items-center px-4 p-2 task-item text-mine-200 rounded-lg w-full cursor-pointer ${isSelected ? 'task-item-selected' : ''}`}
            >

                {/* Toggle Subtasks Icon */}
                {process.fields.subTasks && subtasks && subtasks.length > 0 && (
                    <CaretR className={`w-7 ml-[-10px] scale-[1] origin-center ${isOpen && 'mt-[2px] ml-[-11px] mr-[1px] rotate-90'}`} onClick={() => setIsOpen(!isOpen)} />
                )}

                {/* Task Users Assigned */}
                {task.usersAssigned.length > 0 && (
                    <div className='mr-2 -ml-1'>
                        <UserCircles users={task.usersAssigned.map(findUserByuuId)} />
                    </div>
                )}

                {/* Task Title or Input for Editing */}
                {isSelected && isEditing && editingTaskId === task.id ? (
                    <input
                        key='task.id'
                        type="text"
                        value={defaultTitle}
                        onChange={onTitleChange}
                        onBlur={onTitleBlur}
                        onKeyDown={handleKeyPress}
                        className="flex-1 font-medium my-2 truncate task-title-input"
                        autoFocus
                        style={{ fontSize: '14px' }}
                    />
                ) : (
                    <div
                        onClick={(e) => { handleTitleClick(e, task) }}
                        className="flex-1 min-w-0 font-medium my-2 truncate"
                        style={{ fontSize: '14px' }}
                    >
                        {task.title === 'New Task' ? (
                            <div className='flex gap-1'>
                                <div>{task.title}</div>
                                <Pen alt="Pen" className='h-[18px] w-auto flex-shrink-0' />
                            </div>
                        ) : task.title}
                    </div>
                )}

                {/* Task Icons task status*/}
                {(task.isWarning || warningSubtasks) && (<WarningIcon className={`task-icon ${warningSubtasks ? 'ml-1' : ''}`} />)}
                {process.fields.subTasks && subtasks.length > 0 && !task.isDone && <div className="ml-2 text-sm font-semibold">{doneSubtasksCount}/{subtasks.length}</div>}
                {process.fields.subTasks && areAllSubtasksDone && !task.isDone && !task.isWarning && <CheckGIcon className="ml-1 task-icon" />}
                {!task.isMilestone && task.isDone && !task.isWarning && <CheckIcon className="task-icon" />}
                {!task.isWarning && task.isMilestone && (<Star className={`ml-2 task-icon ${task.isDone ? 'text-[#59C680]' : areAllSubtasksDone && !task.isDone ? 'text-gray' : ''}`} />)}
            </div>

            {/* Subtasksz */}
            {isOpen && subtasks && (
                <div className="pl-5 mt-2 gap-2 flex flex-col"> {/* Tailwind padding adjustment */}
                    {subtasks.map(subtask => (

                        <div
                            key={subtask.id}
                            onClick={(e) => handleTaskClick(e, subtask)}
                            className={`flex items-center px-4 p-2 task-item text-mine-200 rounded-lg w-full cursor-pointer ${subtask.id === selectedTaskId ? 'task-item-selected' : ''}`}
                        >
                            { }
                            {/* Task Users Assigned */}
                            {subtask.usersAssigned.length > 0 && (
                                <div className='mr-2'>
                                    <UserCircles users={subtask.usersAssigned.map(findUserByuuId)} circleSize={32} />
                                </div>
                            )}

                            {/* Task Title or Input for Editing */}
                            {(subtask.id === selectedTaskId) && isEditing && editingTaskId === subtask.id ? (
                                <input
                                    key={subtask.id}
                                    type="text"
                                    value={defaultTitle}
                                    onChange={onTitleChange}
                                    onBlur={onTitleBlur}
                                    onKeyDown={handleKeyPress}
                                    className="flex-1 font-medium my-2 truncate task-title-input"
                                    autoFocus
                                    style={{ fontSize: '14px' }}
                                />
                            ) : (
                                <div
                                    onClick={(e) => { handleTitleClick(e, subtask) }}
                                    className="flex-1 min-w-0 font-medium my-2 truncate"
                                    style={{ fontSize: '14px' }}
                                >
                                    {subtask.title === 'New Task' ? (
                                        <div className='flex gap-1'>
                                            <div>{subtask.title}</div>
                                            <Pen alt="Pen" className='h-[18px] w-auto flex-shrink-0' />
                                        </div>
                                    ) : subtask.title}
                                </div>
                            )}

                            {/* Task Icons */}
                            {subtask.isDone && !subtask.isWarning && <CheckIcon className="task-icon" />}
                            {subtask.isWarning && <WarningIcon className="task-icon" />}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};


const TaskList = ({
    tasks,
    onTaskClick,
    selectedTaskId,
    onTitleClick,
    isEditing,
    defaultTitle,
    onTitleChange,
    onTitleBlur,
    users,
    onCreateTask,
    expandedTasks = [],
    process = { fields: { subTasks: false } }
}) => {
    const listRef = useRef();
    const [subtasksMap, setSubtasksMap] = useState({});

    useEffect(() => {
        // Organize tasks into parent tasks and subtasks
        const parentTasks = {};
        const subtasksMap = {};

        tasks.forEach(task => {
            if (task.parentTaskId) {
                // If task is a subtask, add it to the subtasksMap under its parentTaskId
                if (!subtasksMap[task.parentTaskId]) {
                    subtasksMap[task.parentTaskId] = [];
                }
                subtasksMap[task.parentTaskId].push(task);
            } else {
                // If task is a parent task, add it to the parentTasks
                parentTasks[task.id] = task;
            }
        });

        // Update state to include organized tasks and their references
        setSubtasksMap(subtasksMap);

    }, [tasks]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Escape') {
            e.preventDefault(); // Prevent the default action to avoid submitting forms if any
            e.target.blur(); // This triggers onBlur event which can be used for saving
        }
    };

    const findUserByuuId = (userId) => users.find((user) => user.uuid === userId);

    return (
        <div ref={listRef} className="flex flex-col gap-3 task-list min-w-[200px] max-h-[370px] overflow-y-auto 
        scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full scrollbar-thumb-[#4f69b06f] hover:scrollbar-thumb-[#4F69B0]  scrollbar-track-transparent ">
            {tasks.map((task) => {
                const subtasks = subtasksMap[task.id] || [];

                return (
                    <Task
                        key={task.id}
                        task={task}
                        subtasks={subtasks}
                        selectedTaskId={selectedTaskId}
                        onTaskClick={onTaskClick}
                        isEditing={isEditing}
                        onTitleClick={onTitleClick}
                        onTitleChange={onTitleChange}
                        onTitleBlur={onTitleBlur}
                        handleKeyPress={handleKeyPress}
                        defaultTitle={defaultTitle}
                        findUserByuuId={findUserByuuId}
                        expandedTasks={expandedTasks}
                        process={process}
                    />
                );
            })}

            {onCreateTask && (
                <div onClick={onCreateTask} className="flex items-center px-4 py-2 task-item-add border-2 border-dashed border-gray-300 rounded-lg w-full max-w-52 cursor-pointer">
                    <div className="h-7 w-7 border-2 border-dashed border-gray-300 rounded-full task-item-add mr-2">
                        <div className="h-4 w-4 t-[0px]" style={{ marginLeft: '8.3px' }}>+</div>
                    </div>
                    New Task
                </div>
            )}
        </div>
    );

};

export default TaskList;