import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill stylesheet

const QuillEditor = ({ value, onChange }) => {

    return (
        // Use Tailwind's h-full to make the editor take the full height of its parent
<ReactQuill
    theme="snow"
    value={value}
    onChange={(content) => onChange(content)} // Simplified for HTML content
    modules={QuillEditor.modules}
    formats={QuillEditor.formats}
    style={{ height: '100%', width:'500px' }}
/>

    );
};
// Quill modules to attach to editor
// For toolbar options reference: https://quilljs.com/docs/modules/toolbar/
QuillEditor.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
    ],
    clipboard: {
        // Toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

// Quill editor formats
QuillEditor.formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
];

export default QuillEditor;
