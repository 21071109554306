import React, { useEffect, useState, useRef } from 'react';
import TimeAxis from './TimeAxis';
import Popup from './Popup';
import DraggableTimeline from './DraggableTimeline';

const GanttChart = ({ processData, startDate, endDate, scales, margin, width, height, barHeight, barPadding, onProcessClick, dayInterval }) => {
  const [barElements, setBarElements] = useState([]);
  const [enableClick, setEnableClick] = useState(false);
  const currentDate = new Date();
  scales.xScale.domain([startDate, endDate]);
  const [currentDayX, setCurrentDayX] = useState(scales.xScale(currentDate));
  let hoverTimeoutRef = useRef(null);
  const lastMousePositionRef = useRef({ x: 0, y: 0 });
  const currentMousePositionRef = useRef({ x: 0, y: 0 });
  const hoverIntervalRef = useRef(null);
  const boundaryRef = useRef(null);

  const handleMouseMove = (e) => {
    currentMousePositionRef.current = { x: e.clientX, y: e.clientY };
  };

  const [popup, setPopup] = useState({
    show: false,
    content: null,
    position: { x: 0, y: 0 },
  });

  useEffect(() => {
    // Event listener for mouse movement
    const handleMouseMove = (e) => {
      currentMousePositionRef.current = { x: e.clientX, y: e.clientY };
      // Update popup position if it's visible
      if (popup.show) {
        setPopup(prev => ({ ...prev, position: currentMousePositionRef.current }));
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [popup.show]);

  useEffect(() => {
    setCurrentDayX(scales.xScale(currentDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scales.xScale]);

  useEffect(() => {
    if (!scales.xScale) {
      return; // Early return if xScale is not available
    }

    const newBarElements = processData.map((process, i) => {
      const x = scales.xScale(new Date(process.startTime));
      const totalWidth = scales.xScale(new Date(process.deadlineTime)) - x;
      const percentComplete = process.percentageComplete; // Assumed to be directly from process data
      const completedWidth = totalWidth * (percentComplete / 100);
      const offset = totalWidth * ((100 - percentComplete) / 700);

      return {
        key: `bar-${i}`,
        x: x + margin.left,
        y: (i * (barHeight + barPadding)) + margin.top,
        completedWidth: completedWidth,
        totalWidth: totalWidth,
        height: barHeight,
        color: process.color,
        percentComplete: percentComplete,
        id: process.id,
        offset: offset // Add the offset to the bar element
      };
    });

    setBarElements(newBarElements);
  }, [processData, scales, margin, barHeight, barPadding]);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleBarMouseEnter = (id, e) => {
    // Initialize the last mouse position
    lastMousePositionRef.current = { x: e.clientX, y: e.clientY, time: Date.now() };

    // Clear any existing interval
    if (hoverIntervalRef.current) {
      clearInterval(hoverIntervalRef.current);
    }

    // Setup an interval to continuously check the mouse velocity
    hoverIntervalRef.current = setInterval(() => {
      const currentTime = Date.now();
      const deltaTime = currentTime - lastMousePositionRef.current.time;
      const deltaX = currentMousePositionRef.current.x - lastMousePositionRef.current.x;
      const deltaY = currentMousePositionRef.current.y - lastMousePositionRef.current.y;

      // Calculate the velocity
      const velocity = Math.sqrt(deltaX ** 2 + deltaY ** 2) / deltaTime;

      if (velocity < 0.03) { setEnableClick(true); } else { setEnableClick(false); }
      // If the velocity is low enough, and the popup isn't already shown, show it
      if (velocity < 0.03 && !popup.show) { // Adjust the velocity threshold as needed
        setPopup({
          show: true,
          content: id,
          position: currentMousePositionRef.current,
        });
        clearInterval(hoverIntervalRef.current);
      }

      // Update the last position and time
      lastMousePositionRef.current = { x: currentMousePositionRef.current.x, y: currentMousePositionRef.current.y, time: currentTime };
    }, 100); // Check the velocity every 100 milliseconds
  };

  const handleBarMouseLeave = () => {
    // Clear the interval and hide the popup
    clearInterval(hoverIntervalRef.current);
    setPopup(prev => ({ ...prev, show: false }));
    setEnableClick(false)
  };

  const boxPadding = 60;
  const containerWidth = scales.xScale.range()[1] - scales.xScale.range()[0];

  return (
    <div className="svgContainer relative">
      <div
        className="barContainer relative"
        style={{
          left: `${margin.left - (boxPadding / 2)}px`,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        <DraggableTimeline currentDayX={currentDayX} boundaryRef={boundaryRef} range={containerWidth}>
          <div className={``}>
            <div className="barRef relative left-[-302px]">
              {barElements.map((bar) => (
                <div className="relative" key={bar.key}>
                  <div
                    style={{
                      position: "absolute",
                      left: bar.x + "px",
                      top: bar.y + 24 + "px",
                      width: bar.totalWidth + "px",
                      height: barHeight + "px",
                      overflow: "hidden",
                      borderRadius: "999px",
                    }}
                  >
                    <div
                      onMouseEnter={(e) => handleBarMouseEnter(`${bar.id}`, e)}
                      onMouseLeave={handleBarMouseLeave}
                      onClick={() => {
                        enableClick && onProcessClick(bar.id);
                      }}
                      className={`${enableClick && "cursor-pointer"}`}
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        width: bar.totalWidth + "px",
                        height: barHeight + "px",
                        backgroundColor: `${bar.color}40`,
                        zIndex: 2,
                        borderRadius: "999px",
                      }}
                    />

                    <div
                      style={{
                        position: "relative",
                        width: bar.totalWidth + "px",
                        height: barHeight + "px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          left: `${bar.totalWidth * (-1 * (1 - bar.percentComplete / 100)) + bar.offset}px`,
                          top: "0px",
                          width: bar.totalWidth + "px",
                          height: barHeight + "px",
                          backgroundColor: bar.color,
                          borderRadius: "999px",
                          pointerEvents: "none",
                          zIndex: 2,
                        }}
                        className="flex items-center justify-end"
                      >
                        <div
                          className="pr-4"
                          style={{
                            fontSize: "15px",
                            fontWeight: "800",
                            textAnchor: "middle",
                            color: "white",
                            pointerEvents: "none",
                            display: `${bar.totalWidth - (bar.totalWidth * (1 - bar.percentComplete / 100)) < 40 ? "none" : "block"}`,
                          }}
                        >
                          {bar.percentComplete}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Conditionally render the Popup component */}
          {popup.show && (
            <Popup
              show={popup.show}
              mouseX={popup.position.x}
              mouseY={popup.position.y}
              content={popup.content}
              onMouseEnter={() => {
                if (hoverTimeoutRef.current) {
                  clearTimeout(hoverTimeoutRef.current);
                }
              }}
              onMouseLeave={() => {
                hoverTimeoutRef.current = setTimeout(() => {
                  setPopup((prev) => ({ ...prev, show: false }));
                }, 250);
              }}
            />
          )}
          <div className={`absolute items-center z-[-1]`}>
            <div ref={boundaryRef} className={` h-[${height}px] relative`}>
              <TimeAxis
                scales={scales}
                height={height}
                margin={margin}
                currentDate={currentDate}
                dayInterval={dayInterval}
              />
            </div>
          </div>
        </DraggableTimeline>
      </div>
    </div>
  );
};

export default GanttChart;