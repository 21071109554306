import React, { useEffect, useRef } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const DatePickerPopup = ({ visible, deadline, cursorPosition = { x: 0, y: 0 }, onDeadlineSelect, onClickOut, onClickBut, toggleRef }) => {
  const popupRef = useRef();

  // Calculate the current year minus 2 and current year plus 5
  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 2;
  const toYear = currentYear + 5;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && toggleRef.current && !toggleRef.current.contains(event.target)) {
        onClickOut(); // Close the popup
      } else if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClickBut();
      }
    };

    if (visible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible, onClickOut, onClickBut, toggleRef]);

  if (!visible) return null;

  const popupStyle = {
    position: 'absolute',
    zIndex: 1000,
    left: `${cursorPosition.x}px`,
    top: `${cursorPosition.y + 10}px`,
    transform: 'translateX(-25%)'
  };

  return (
    <div ref={popupRef} className="absolute z-50 bg-white shadow-lg rounded-xl" style={popupStyle}>
      <DayPicker
        captionLayout="dropdown-buttons"
        fromYear={fromYear}
        toYear={toYear}
        showOutsideDays
        weekStartsOn={1}
        onDayClick={(date) => onDeadlineSelect(date)}
        selected={deadline}
        defaultMonth={deadline}
        styles={{
          caption: { fontFamily: `'Nunito', sans-serif`,color: `#253766`, fontWeight: 'bold'  },
          head: { fontWeight: 700, color: `#253766`, },
          day: { fontWeight: 800, fontFamily: 'Nunito', color: `#4F69B0` },
          dropdown_year: { cursor: 'pointer', },
          dropdown_month: { cursor: 'pointer', },
          day_selected: { color: 'red' },
        }}
      />
    </div>
  );
};

export default DatePickerPopup;
