import React from 'react';
import './TripleToggleSwitch.css';

const TripleToggleSwitch = ({ onChange, activeState }) => {
    const handleOnChange = (value) => {
        // Call the passed onChange function with the new value
        onChange(value);
    };

    return (
        <div className="toggle-container shadow-lg">
            <div className={`switch ${activeState}-position`}></div>
            <input
                checked={activeState === 'left'}
                onChange={() => handleOnChange('left')}
                id="left"
                type="radio"
                value="left"
            />
            <label htmlFor="left" className={activeState === 'left' ? 'active' : ''}>
                <h4 className='text-gray-400'>Week</h4>
            </label>

            <input
                checked={activeState === 'center'}
                onChange={() => handleOnChange('center')}
                id="center"
                type="radio"
                value="center"
            />
            <label htmlFor="center" className={activeState === 'center' ? 'active' : ''}>
                <h4 className='text-gray-400'>Month</h4>
            </label>

            <input
                checked={activeState === 'right'}
                onChange={() => handleOnChange('right')}
                id="right"
                type="radio"
                value="right"
            />
            <label htmlFor="right" className={activeState === 'right' ? 'active' : ''}>
                <h4 className='text-gray-400'>Year</h4>
            </label>
        </div>
    );

};

export default TripleToggleSwitch;