import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserUpdate, useUpdateUserPassword, useGetUser, useAuth } from './Database'; // Ensure these paths match your project structure
import UserCircles from './comp/UserCircles';

const UserOnboard = () => {
  const navigate = useNavigate();
  const { isSession } = useAuth();
  const userUuid = isSession?.user?.id;
  const { data: userData, isLoading } = useGetUser(userUuid);
  const managerUuid = userData?.managerUuid[0];
  const { data: managerData, isLoading2 } = useGetUser(managerUuid);
  const userUpdate = useUserUpdate();
  const updateUserPassword = useUpdateUserPassword();
  const [userDetails, setUserDetails] = useState({
    email: '',
    name: '',
    type: '',
    title: '',
    company: '',
    color: '#000000',
  });
  const [userPassword, setUserPassword] = useState('');

  useEffect(() => {
    if (userData) {
      setUserDetails({
        email: userData.email,
        name: userData.name,
        type: userData.type,
        title: userData.title,
        company: userData.company,
        color: userData.color || '#000000',
      });
    }
  }, [userData]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setUserPassword(value);
    } else {
      setUserDetails(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleColorChangeComplete = (color) => {
    setUserDetails(prev => ({ ...prev, color: color.hex }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Update user details
    userUpdate.mutate({ ...userDetails, uuid: userUuid }, {
      onSuccess: () => {
        // Only attempt to update the password if it's provided
        if (userPassword) {
          updateUserPassword.mutate({ password: userPassword }, {
            onSuccess: () => navigate('/timeline'),
            onError: error => console.error('Password update error:', error),
          });
        } else {
          // navigate('/timeline');
        }
      },
      onError: error => console.error('User details update error:', error),
    });
  };

  if (isLoading || isLoading2) return <div>Loading user details...</div>;

  return (
    <div className="container-lg lg:container m-auto relative top-[-5rem] left-[-4rem]">
      <div className="max-w-md m-auto">
        <h2 className="text-center text-3xl font-extrabold text-mine">Edit Your Profile</h2>
        <div className="justify-center flex my-10">
          <UserCircles users={[{ name: userDetails.name, color: userDetails.color }]} circleSize={100} isEdit={true} handleColorChangeComplete={handleColorChangeComplete} />
        </div>
        <form className="space-y-3" onSubmit={handleSubmit}>
          {/* Display only, fields disabled */}
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            required
            className="col-span-3 appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
            style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
            value={userDetails.name}
            readOnly
          // onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            required
            className="appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
            style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
            value={userPassword}
            onChange={handleChange}
          />
          <div className="grid grid-cols-4 gap-3">
            {(managerData && (
              <input
                type="text"
                name="Managed By"
                placeholder="Managed By"
                className="col-span-2 appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
                style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                value={managerData.name + userData?.managerUuid.length>1 && ' & more'}
                // onChange={handleChange}
                readOnly
              />
            ))}
            <input
              type="text"
              name="company"
              placeholder="Company"
              className="col-span-2 appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
              style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
              value={userDetails.company}
              // onChange={handleChange}
              readOnly
            />

          </div>
          <div className="flex items-center gap-3"></div>
          {/* Submit Button */}
          <button
            type="submit"
            className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-mine hover:bg-mine-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '20px' }}
          >
            Join
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserOnboard;