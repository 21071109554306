import React, { useEffect, useState, useRef } from 'react';
import GanttChart from './GanttChart';
import Infobox from './Infobox';
import * as d3 from 'd3';

const GanttChartReact = ({ processData, startDate, endDate, onProcessClick, targetZoom, timeScale, onProcessDelete }) => {
  const [dayInterval, setDayInterval] = useState(30);
  const infoboxWidth = 220; // Fixed width for the infoboxes

  const barHeight = 64; // Fixed height for each bar
  const barPadding = 15; // Space between bars

  const margin = { top: 60, right: 15, bottom: 20, left: 290 };
  const height = processData.length * 80; // Adjust as needed
  const chartWidth = '100%'; // Fixed width for the chart or '100%' for full width

  const containerRef = useRef(null);
  const [availableWidth, setAvailableWidth] = useState(0);
  const [scales, setScales] = useState({ xScale: null }); // Add scales state
  const [init, setInit] = useState(false);

  // Function to update the available width
  useEffect(() => {
    if (!processData || processData.length === 0 || !startDate || !endDate || init) {
      return;
    }
    // Function to update the available width
    const updateWidth = () => {
      // Check if the current ref is set and then update the width
      if (containerRef.current) {
        setAvailableWidth(containerRef.current.offsetWidth); //-1 fix for topbar overflow
      }
    };
    updateWidth();
    // // Add event listener for window resize
    window.addEventListener('resize', updateWidth);

    // // Set up the xScale with the given startDate and endDate
    const xScale = d3.scaleTime()
      .domain([startDate, endDate]) // Adjust domain with calculated times
      .range([0, 1000]);

    setScales({ xScale });

    // // Cleanup event listener
    return () => {
      window.removeEventListener('resize', updateWidth);
      setInit(true);
    };
  }, [processData, startDate, endDate, chartWidth, margin.left, margin.right, height, init]);

  const zoomTo = (targetZoom) => {
    setScales(scales => {
      const currentRange = scales?.xScale?.range() || [0, 1000]; // Provide a default range if xScale is null or undefined
      const newRange = [currentRange[0], targetZoom]; // Adjust the range based on the targetZoom

      const newXScale = scales.xScale ? scales.xScale.copy().range(newRange) : null;
      setDayInterval(calculateDayInterval());
      // drawXAxis(newXScale);

      return { ...scales, xScale: newXScale };
    });
  };

  useEffect(() => {
    if (targetZoom !== null) zoomTo(targetZoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetZoom]);

  function calculateDayInterval() {
    if (timeScale === 'left') return 1;
    else if (timeScale === 'center') return 3;
    else if (timeScale === 'right') return 30;
    else return 1;
  }

  // Calculate the y position for each process/infobox
  const getYPosition = (index) => {
    return index * (barHeight + barPadding) + margin.top + 25; // Calculate the top position based on the index
  };

  const totalBarsHeight = processData.length * barHeight;
  const totalPaddingHeight = (processData.length - 1) * barPadding; // padding between bars
  const totalHeight = totalBarsHeight + totalPaddingHeight + margin.top + margin.bottom + 50;

  // Container for the scrollable part of the chart
  const chartContainerStyle = {
    height: `100%`,
    width: `${chartWidth + 50}px`, // Use '100%' if the width should be responsive
    paddingTop: `25px`,
    paddingBottom: `25px`
  };

  return (
    <div className={`relative w-full h-[${totalHeight}px] right-[-12.5px] lg:right-0`} ref={containerRef}>
      {scales.xScale && ( // Check if scales.xScale is not null or undefined
        <>
          {/* Render infoboxes */}
          {processData.map((process, i) => (
            <div
              key={process.id}
              className='cursor-pointer pt-6'
              onClick={() => onProcessClick(process.id)}
              style={{
                position: 'absolute',
                left: '20px',
                top: `${getYPosition(i)}px`,
                width: `${infoboxWidth}px`, // Fixed width for infoboxes
                zIndex: processData.length - i,
              }}
            >
              <Infobox process={process} onProcessDelete={onProcessDelete} />
            </div>
          ))}

          {/* Scrollable chart area */}
          <div style={{ ...chartContainerStyle, width: `${availableWidth}px` }}>
            <GanttChart
              key={'12334'}
              processData={processData}
              startDate={startDate}
              endDate={endDate}
              scales={scales}
              margin={margin}
              width={availableWidth - margin.left - margin.right}
              height={totalHeight}
              barHeight={barHeight}
              barPadding={barPadding}
              onProcessClick={onProcessClick}
              dayInterval={dayInterval}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GanttChartReact;