import React, { useState, useEffect, useRef, useCallback } from 'react';

const DraggableTimeline = ({ children, boundaryRef, currentDayX }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const scrollPosition = currentDayX - (containerWidth / 3);
      containerRef.current.scrollLeft = scrollPosition;
    }
  }, [currentDayX]);

  const handleMouseDown = useCallback((e) => {
    setIsDragging(true);
    const containerRect = containerRef.current.getBoundingClientRect();
    setStartX(e.clientX - containerRect.left);
    e.preventDefault();
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!isDragging || !containerRef.current || !boundaryRef.current) return;
  
    const containerRect = containerRef.current.getBoundingClientRect();
    const boundaryRect = boundaryRef.current.getBoundingClientRect();
  
    // Calculate the maximum allowed scroll position considering the offset
    const maxScrollLeft = boundaryRect.width - containerRect.width - 1;
  
    // Calculate the new scroll position
    let newScrollLeft = containerRef.current.scrollLeft - (e.clientX - (containerRect.left + startX));
  
    // Clamp the new scroll position within the allowed range
    newScrollLeft = Math.max(0, Math.min(newScrollLeft, maxScrollLeft));
  
    // Update the scroll position
    containerRef.current.scrollLeft = newScrollLeft;
  
    setStartX(e.clientX - containerRect.left);
  }, [isDragging, startX, containerRef, boundaryRef]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  if (!children) return <div></div>;
  return (
    <div
      className="dragRef shadow-xl rounded-3xl overflow-hidden"
      ref={containerRef}
      style={{ overflowX: 'hidden' }}
      onMouseDown={handleMouseDown}
    >
      {React.Children.map(children, child =>
        child ? React.cloneElement(child, { className: 'w-max flex' }) : null
      )}
    </div>
  );
};

export default DraggableTimeline;