import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CirclePicker } from 'react-color';
import { useSignUp, useResetPassword, useGetUsers } from './Database';
import UserCircles from './comp/UserCircles';
import UserSelectionPopup from './comp/UserSelectionPopup';

const UserCreate = () => {
  const navigate = useNavigate();
  const signUp = useSignUp();
  const resetPassword = useResetPassword();
  const { data: allUsers } = useGetUsers();
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
    name: '',
    type: 'company',
    title: '',
    color: '#000000',
    manager: { uuid: '', name: 'Manager', color: '#000000' },
    company: { uuid: '', name: 'Company', color: '#000000' },
    settings: { notifMail: false }
  });
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [showCompanySelectionPopup, setShowCompanySelectionPopup] = useState(false);
  const [showManagerSelectionPopup, setShowManagerSelectionPopup] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const companyRef = useRef(null);
  const managerRef = useRef(null);

  useEffect(() => {
    // Set default values for company and manager on page load
    setUserDetails(prev => ({
      ...prev,
      company: { name: 'Company', color: '#000000' },
      manager: { uuid: '', name: 'Manager', color: '#000000' },
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleColorChangeComplete = (color) => {
    setUserDetails(prev => ({ ...prev, color: color.hex }));
    setIsColorPickerOpen(false);
  };

  const handleCompanyFieldClick = useCallback((event) => {
    event.stopPropagation();
    const elementRect = companyRef.current.getBoundingClientRect();
    setCursorPosition({
      x: elementRect.left - 150,
      y: elementRect.top // Position the popup right below the element
    });
    console.log(elementRect)
    setShowCompanySelectionPopup(showCompanySelectionPopup === 2 ? false : true);
  }, [showCompanySelectionPopup]);

  const handleManagerFieldClick = useCallback((event) => {
    event.stopPropagation();
    const elementRect = managerRef.current.getBoundingClientRect();
    setCursorPosition({
      x: elementRect.left,
      y: elementRect.top // Position the popup right below the element
    });
    setShowManagerSelectionPopup(showManagerSelectionPopup === 2 ? false : true);
  }, [showManagerSelectionPopup]);

  const handleCompanySelect = useCallback((selectedCompanies) => {
    const selectedCompany = allUsers.find(user => selectedCompanies.includes(user.uuid));
    if (selectedCompany) {
      setUserDetails(prev => ({
        ...prev,
        company: {
          uuid: selectedCompany.uuid || '',
          name: selectedCompany.name || 'Company',
          color: selectedCompany.color || '#000000',
        },
        manager: { uuid: '', name: 'Manager', color: '#000000' }, // Reset manager when changing company
      }));
    } else {
      setUserDetails(prev => ({
        ...prev,
        company: { uuid: '', name: 'Company', color: '#000000' },
        manager: { uuid: '', name: 'Manager', color: '#000000' },
      }));
    }
    setShowCompanySelectionPopup(false);
  }, [allUsers]);

  const handleManagerSelect = useCallback((selectedManagers) => {
    const selectedManager = allUsers.find(user => selectedManagers.includes(user.uuid) && user.type === 'manager' && user.companyUuid === userDetails.company.uuid);
    if (selectedManager) {
      setUserDetails(prev => ({
        ...prev,
        manager: {
          uuid: selectedManager.uuid,
          name: selectedManager.name || 'Manager',
          color: selectedManager.color || '#000000',
        },
      }));
    } else {
      setUserDetails(prev => ({
        ...prev,
        manager: { uuid: '', name: 'Manager', color: '#000000' },
      }));
    }
    setShowManagerSelectionPopup(false);
  }, [allUsers, userDetails.company.uuid]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userDetails.email || userDetails.email.length === 0) {
      window.alert("Email is required duh!");
      return;
    }

    if (userDetails.type === 'employee' || userDetails.type === 'manager') {
      if (!userDetails.company.uuid || userDetails.company.uuid.length === 0) {
        window.alert(`You need to select a company for employee/managers!`)
        return;
      }
    }
    if (userDetails.type === 'user') {
      userDetails.title = 'Personal';
    }

    if (userDetails.type === 'company') {
      userDetails.color = 'white';
    }

    signUp.mutate({ ...userDetails, password: userDetails.password }, {
      onSuccess: () => {
        const redirectTo = 'https://organigram.axydavid.com/onboard';
        resetPassword.mutate({ email: userDetails.email, redirectTo }, {
          onSuccess: () => {
            console.log("Invitation sent successfully");
            navigate('/timeline');
          },
          onError: (error) => console.error('Password reset error:', error)
        });
      },
      onError: (error) => console.error('Signup error:', error)
    });
  };

  return (
    <div className="container-lg lg:container m-auto relative lg:top-[-5rem] lg:left-[-4rem]">
      <div className="max-w-md m-auto">
        <h2 className="text-center text-3xl font-extrabold text-mine mb-6">Create {userDetails.type === 'user' && 'personal '}{userDetails.type} account</h2>
        {userDetails.type !== 'company' && userDetails.type !== 'user' && (
          <div className="justify-center flex mt-3">
            <UserCircles users={[{ name: userDetails.name, color: userDetails.color }]} circleSize={80} />
          </div>
        )}
        <form className="space-y-3 mt-3" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-3">
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              className="appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
              style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
              value={userDetails.email}
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              required
              className="appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
              style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
              value={userDetails.password}
              onChange={handleChange}
            />
          </div>
          <div className="grid grid-cols-4 gap-3">
            {(userDetails.type === 'company' || userDetails.type === 'user') ? (
              <>
                <input
                  type="text"
                  name="name"
                  placeholder={userDetails.type === 'company' ? "Company Name" : "Name"}
                  className="col-span-3 appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
                  style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                  value={userDetails.name}
                  onChange={handleChange}
                />

                <select
                  name="type"
                  required
                  className="rounded-full text-gray-900 px-4 py-3 sm:text-sm border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                  value={userDetails.type}
                  onChange={handleChange}
                >
                  <option value="company">Company</option>
                  <option value="user">User</option>
                  <option value="manager">Manager</option>
                  <option value="employee">Employee</option>
                </select>
              </>
            ) : (
              <>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  required
                  className="col-span-3 appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
                  style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                  value={userDetails.name}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="rounded-full px-4 py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  style={{ backgroundColor: userDetails.color }}
                  onClick={() => setIsColorPickerOpen(true)}
                >
                  Pick Color
                </button>
              </>
            )}
          </div>

          {userDetails.type !== 'company' && userDetails.type !== 'user' ? (
            <div className="grid grid-cols-4 gap-3">
              <input
                type="text"
                name="title"
                placeholder="Position"
                className="col-span-3 appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
                style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                value={userDetails.title}
                onChange={handleChange}
              />
              <select
                name="type"
                required
                className="rounded-full text-gray-900 px-4 py-3 sm:text-sm border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                value={userDetails.type}
                onChange={handleChange}
              >
                <option value="company">Company</option>
                <option value="user">User</option>
                <option value="manager">Manager</option>
                <option value="employee">Employee</option>
              </select>
            </div>
          ) : (
            <div className="col-span-3" />
          )}

          {userDetails.type !== 'company' && userDetails.type !== 'user' && (
            <div className="flex items-center justify-center w-full gap-2">
              <div className="relative">
                <div className="cursor-pointer" ref={companyRef} onClick={handleCompanyFieldClick}>
                  <UserCircles users={[userDetails.company]} circleSize={48} />
                </div>
              </div>
              <div className="relative">
                <div
                  className={`cursor-pointer ${!userDetails.company.uuid ? 'opacity-50 pointer-events-none' : ''}`}
                  ref={managerRef} onClick={handleManagerFieldClick}>
                  <UserCircles users={[userDetails.manager]} circleSize={48} />
                </div>
              </div>
            </div>
          )}

          <button
            type="submit"
            className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-mine hover:bg-mine-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '20px' }}
          >
            Sign Up
          </button>
        </form>
        <div className='items-center flex relative'>
        {showCompanySelectionPopup && companyRef.current && (
          <UserSelectionPopup
            visible={showCompanySelectionPopup}
            cursorPosition={{x:cursorPosition.x/5, y:-65}}
            selectedUsers={[userDetails.company.uuid]}
            users={allUsers.filter(user => user.type === 'company')}
            onUserSelect={handleCompanySelect}
            onClickOut={() => setShowCompanySelectionPopup(false)}
            onClickBut={() => setShowCompanySelectionPopup(2)}
            toggleRef={companyRef}
            userType="company"
          />
        )}

        {showManagerSelectionPopup && managerRef.current && userDetails.company.uuid && (
          <UserSelectionPopup
            visible={showManagerSelectionPopup}
            cursorPosition={{x:cursorPosition.x/4.5, y:-65}}
            selectedUsers={[userDetails.manager.uuid]}
            users={allUsers.filter(user => user.type === 'manager' && user.companyUuid === userDetails.company.uuid)}
            onUserSelect={handleManagerSelect}
            onClickOut={() => setShowManagerSelectionPopup(false)}
            onClickBut={() => setShowManagerSelectionPopup(2)}
            toggleRef={managerRef}
            userType="manager"
          />
        )}
      </div>

      </div>
      {isColorPickerOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto " onClick={() => setIsColorPickerOpen(false)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="shadow-2xl inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:p-6">
              <CirclePicker color={userDetails.color} onChangeComplete={handleColorChangeComplete} />
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default UserCreate;