import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from './Database';
import { ReactComponent as Left } from './icons/left.svg';

const LogIn = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  // Email and password state management remains the same

  const handleResetPassword = async (event) => {
    event.preventDefault();
  
    try {
      await resetPassword(email);
      const confirm = window.confirm('Password reset email sent!');
      if (confirm) navigate('/');

    } catch (error) {
      window.alert('Password reset error:', error.message);
    }
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous location
  };

  return (
    <div className="container-lg lg:container m-auto ">

      <div className="max-w-md m-auto">
        <div className='flex'>
          <div onClick={handleBackClick} className="cursor-pointer flex mr-4">
            <Left className="m-auto special-text" style={{ width: '40px', height: '31px' }} />
          </div>

          <h2 className="text-center text-3xl font-extrabold special-text">
            Reset Password
          </h2>
        </div>
        <form onSubmit={handleResetPassword} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">Email address</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-full relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-200 text-mine focus:outline-none focus:ring-mine-50 focus:border-mine-50 focus:z-10 sm:text-sm"
                placeholder="Email address"
                style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '16px' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-mine hover:bg-mine-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mine-50"
              style={{ fontFamily: 'Nunito, sans-serif', fontWeight: '800', fontSize: '20px' }}
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
