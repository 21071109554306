import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as NotifBell } from './icons/bell.svg';
import UserCircles from './comp/UserCircles';
import { useGetCurUser, useLogout, useGetNotifications, useMarkNotificationAsRead, useSendNotificationEmail, useGlobalNotifications } from './Database';
import { ReactComponent as WarningIcon } from './icons/warning.svg'; // Adjust the path

const TopBar = () => {
  const location = useLocation();
  let openNotif = new URLSearchParams(location.search).get('notif') || false;
  const userData = useGetCurUser();
  const { data: notifications } = useGetNotifications(userData?.uuid);
  const { data: globalNotifications } = useGlobalNotifications();
  const markNotificationAsRead = useMarkNotificationAsRead();
  const sendNotificationEmail = useSendNotificationEmail();

  const navigate = useNavigate();
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 10 }); // Default right to 20px
  const [showPopup, setShowPopup] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const toggleRef = useRef(null);
  const notificationsRef = useRef(null);
  const logoutMutation = useLogout();
  const popupRef = useRef(null);
  const notifRef = useRef(null);
  const prevNotificationsRef = useRef();
  const [lastNotifSentAt, setLastNotifSentAt] = useState(null);

  const handleNotifClick = useCallback(() => {
    setShowPopup(false);
    setShowNotifications(!showNotifications);
    const buttonRect = notifRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;

    let calculatedRight = viewportWidth - buttonRect.right;
    calculatedRight = calculatedRight < 10 ? 10 : calculatedRight;

    setPopupPosition({
      top: buttonRect.bottom + 10,
      right: calculatedRight,
    });
  }, [showNotifications]); // Add any other dependencies if needed

  const handleToggleClick = () => {
    setShowNotifications(false);
    setShowPopup(!showPopup);
    const buttonRect = toggleRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;

    // Calculate the right position
    let calculatedRight = viewportWidth - buttonRect.right;
    calculatedRight = calculatedRight < 10 ? 10 : calculatedRight;

    setPopupPosition({
      top: buttonRect.bottom + 10, // Position under the button
      right: calculatedRight,
    });
  };

  const handleLogout = () => {
    logoutMutation.mutate({}, {
      onSuccess: () => navigate('/'),
      onError: (error) => console.error('Logout error:', error),
    });
    setShowPopup(false);
  };

  useEffect(() => {
    const sendEmailForFirstNotification = async () => {
      // Proceed only if there's at least one notification
      if (globalNotifications?.length > 0) {
        const now = new Date();

        // Check if at least 60 seconds have passed since the last email was sent
        if (!lastNotifSentAt || now - new Date(lastNotifSentAt) >= 60000) {
          const firstNotification = globalNotifications[0]; // Focus on the first notification
          // Assuming sendNotificationEmail is a mutation to send an email
          sendNotificationEmail.mutate({
            userId: firstNotification.toUuid, // Assuming toUuid is the user's ID
          }, {
            onSuccess: () => {
              console.log("Email sent successfully.");
              setLastNotifSentAt(new Date()); // Update the timestamp
            },
            onError: (error) => {
              console.error("Failed to send notification email:", error);
            },
          });
        }
      }
    };

    // Debounce mechanism
    const handler = setTimeout(() => {
      sendEmailForFirstNotification();
    }, 60000); // Wait 6 seconds before sending the email

    return () => clearTimeout(handler); // Cleanup on component unmount or effect re-run
  }, [globalNotifications, sendNotificationEmail, lastNotifSentAt]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && !toggleRef.current.contains(event.target)) {
        setShowPopup(false);
      }
      if (notificationsRef.current && !notificationsRef.current.contains(event.target) && !notifRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleItemClick = () => setShowPopup(false);
  const handleNotifItemClick = useCallback((notification) => {
    markNotificationAsRead.mutate(notification.id, {
      onSuccess: () => {
        // Optional: Refresh notifications list or update UI accordingly
      }
    });
    const url = `/process?id=${notification.processId}&task=${notification.taskId}`;
    setShowNotifications(false);
    navigate(url);
  }, [markNotificationAsRead, navigate]);

  useEffect(() => {
    if (!notifications || !userData) return;

    // Check if the user has enabled in-app notifications
    const shouldShowNotifications = userData.settings?.notif;

    if (!shouldShowNotifications) return;

    // Request notification permission on component mount
    if (Notification.permission !== "granted" && Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      });
    }

    let unreadNotif = notifications.filter(notification => !notification.isSeen);

    // Check for new notifications by comparing the current notifications against the previous ones
    if (unreadNotif?.length > 0 && Notification.permission === "granted") {
      const prevNotifications = prevNotificationsRef.current;
      if (!prevNotifications || unreadNotif?.length > prevNotifications?.length) {
        new Notification(`${unreadNotif.length !== 1 ? '🔔 You have ' + unreadNotif.length + ' new notifications!' : '🔔 ' + unreadNotif[0].message}`, {
          body: `Click here to see ${unreadNotif.length !== 1 ? 'them' : 'task'}`,
          // Optional: Add an icon URL
        }).onclick = () => {
          if (unreadNotif.length > 1) {
            // Open the notification popup
            setShowNotifications(true);
          } else if (unreadNotif.length === 1) {
            // Directly handle the notification item click for the single notification
            handleNotifItemClick(unreadNotif[0]);
          }
        };
      }
    }

    // Update the ref with the current notifications after handling
    prevNotificationsRef.current = unreadNotif;
  }, [notifications, handleNotifItemClick, userData]);

  useEffect(() => {
    const checkAndTriggerNotifPopup = (note) => {
      if (!!note === true) {
        handleNotifClick(); // This directly calls the logic to show the notification popup
        // eslint-disable-next-line react-hooks/exhaustive-deps
        openNotif = false; // Reset openNotif to prevent repeated triggers
      }
    };
    checkAndTriggerNotifPopup(openNotif);
  }, [openNotif]); // Empty dependency array ensures this runs once on component mount

  if (!userData || !notifications || !globalNotifications) return <div>Loading....</div>; // Handle undefined userData

  return (
    <div className="flex items-center justify-end pt-10 px-10 relative " >

      <div ref={notifRef} className="notification-bell-container relative">
        <NotifBell className="h-8 w-8 text-gray-600 mr-4 cursor-pointer" onClick={handleNotifClick} />
        {notifications?.length > 0 && (
          <div className="absolute top-[-3px] right-[19.25px] bg-red-500 text-white text-2xs rounded-full h-3 w-3 flex items-center justify-center">
            {/* {notifications.length} */}
          </div>
        )}
      </div>

      <div ref={toggleRef} className="flex items-center cursor-pointer" onClick={handleToggleClick}>
        {userData && <UserCircles users={[userData]} circleSize={45} />}
        <div className='ml-2 lg:block hidden'>
          <div className="font-extrabold special-text mb-[-5px]">{userData?.name}</div>
          <div className="text-2xs text-gray-300" style={{ fontSize: '14px' }}>{userData.type !== 'company' ? userData?.title : 'Company'}</div>
        </div>
      </div>

      {showPopup && (
        <div
          ref={popupRef}
          className="flex flex-col absolute bg-white shadow-lg p-2 rounded-xl"
          style={{
            top: `${popupPosition.top}px`, right: `${popupPosition.right}px`, minWidth: '150px',
            transform: 'translateX(6%)'
          }}
        >
          {/* style={{ minWidth: '150px', transform: `translateY(calc(100% - ${popupHeight}px))` }} */}

          {(userData.type === 'manager' || userData.type === 'company') && <Link to="/invite" onClick={handleItemClick} className="px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium ">Invite User</Link>}
          {userData.type === 'admin' && <Link to="/create" onClick={handleItemClick} className="px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium ">Create User</Link>}
          {(userData.type === 'admin' || userData.type === 'manager' || userData.type === 'company') && <Link to="/manager" onClick={handleItemClick} className="px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium ">Management</Link>}
          <Link to="/settings" onClick={handleItemClick} className="px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium ">Settings</Link>
          <button onClick={handleLogout} className="w-full text-left px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium">
            Logout
          </button>
        </div>
      )}

      {/* Notifications Popup */}
      {(showNotifications) && (

        <div
          ref={notificationsRef}
          className="flex flex-col absolute bg-white shadow-lg p-2 rounded-xl"
          style={{
            top: `${popupPosition.top}px`, right: `${popupPosition.right}px`, minWidth: '250px',
            transform: 'translateX(90px)'
          }}
        >
          {notifications.map(notification => (
            <div key={notification.id} onClick={() => handleNotifItemClick(notification)}
              className={`${notification.isSeen ? '' : 'bg-[#fee2e240]'} px-2 py-2 hover:bg-mine-10 flex rounded-lg items-center cursor-pointer`}>
              {notification.type === 'warning' && (
                <WarningIcon className="mx-1 h-5 w-5 text-red-500 flex-shrink-0" />
              )}
              <div className="text-gray-400 px-2 font-medium ">
                {notification.message}
              </div>
            </div>
          ))}
          {notifications?.length === 0 && (
            <div className="text-gray-100 p-4 m-auto font-medium ">
              No notifications
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TopBar;
