import React, { useState, useEffect, useRef } from 'react';
import UserCircles from './UserCircles';
import { useGetUniqueFields, useUpdateUserQuotas, useUpdateUser } from '../Database';
import UserSelectionPopup from './UserSelectionPopup';

const UserCard = ({
    user,
    allUsers,
    handleDisableUser,
    handleDeleteUser,
    handleResetUser
}) => {
    const { data: uniqueFields } = useGetUniqueFields();
    const updateUserQuotas = useUpdateUserQuotas();
    const [quotas, setQuotas] = useState(user.quotas || {});
    const [showUserSelectionPopup, setShowUserSelectionPopup] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [selectedManagers, setSelectedManagers] = useState(user.managerUuid || []);
    const toggleRef = useRef();
    const popupRef = useRef();
    const updateUser = useUpdateUser();
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef();
    const butRef = useRef();

    useEffect(() => {
        setQuotas(user.quotas || {});
        setSelectedManagers(user.managerUuid || []);
    }, [user.quotas, user.managerUuid]);

    const handleTypeChange = (e) => {
        updateUser.mutate({ uuid: user.uuid, type: e.target.value });
    };

    const handleQuotaChange = (field, value) => {
        setQuotas((prevQuotas) => ({
            ...prevQuotas,
            [field]: value,
        }));
    };

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            updateUserQuotas.mutate({
                uuid: user.uuid,
                quotas,
            });
        }, 1000);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [quotas, updateUserQuotas, user.uuid]);

    const handleManagerChange = (newManagerIds) => {
        setSelectedManagers(newManagerIds);
        updateUser.mutate({ uuid: user.uuid, managerUuid: newManagerIds }, {
            onSuccess: () => {
                console.log('Managers updated successfully');
            },
            onError: (error) => {
                console.error('Failed to update managers:', error);
            },
        });
    };

    const openUserSelectionPopup = (event) => {
        setShowUserSelectionPopup(true);
        const elementRect = event.target.getBoundingClientRect();
        setCursorPosition({
            x: elementRect.left,
            y: elementRect.bottom,
        });
    };

    const closePopup = () => {
        setShowUserSelectionPopup(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target) &&
                toggleRef.current &&
                !toggleRef.current.contains(event.target)
            ) {
                closePopup();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = (e) => {
        e.stopPropagation();
        setShowMenu(!showMenu);
    };

    const menuZIndex = 100;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (butRef.current && butRef.current.contains(event.target)) {
                return;
            }
            else if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="bg-white shadow-lg rounded-3xl p-8 gap-4 flex items-center w-fit relative">
            {user.isDisabled && (
                <h4 className='absolute top-4 right-6 text-gray-400 font-bold'>
                    Disabled
                </h4>
            )}
            <div>
                <div className='font-extrabold special-text'></div>
                <div className="flex">
                    <div className='flex justify-center'>
                        <UserCircles users={[user]} circleSize={54} />
                    </div>
                    <div className="ml-4 flex flex-col justify-center ">
                        <span className="font-medium">{user.name}</span>
                        <span className='text-gray-500 text-sm'>{user.email}</span>
                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <div className='font-extrabold special-text'>Type</div>
                <select
                    value={user.type}
                    onChange={handleTypeChange}
                    className="border border-gray-300 rounded px-2 py-1"
                >
                    <option value="manager">Manager</option>
                    <option value="employee">Employee</option>
                </select>
            </div>

            <div className="flex flex-col">
                <div className='font-extrabold special-text'>Manager</div>
                <div ref={toggleRef} className="flex items-center gap-2 cursor-pointer" onClick={openUserSelectionPopup}>
                    {selectedManagers.length > 0 ? (
                        <UserCircles
                            users={allUsers.filter((u) => selectedManagers.includes(u.uuid))}
                        />
                    ) : (
                        <span className="text-gray-500">None</span>
                    )}
                </div>
            </div>

            <div className=" border rounded h-[50px] mx-4" />

            {uniqueFields &&
                <div>
                    <div className='font-extrabold special-text mt-[-20px] '>Quotas</div>
                    <div className='flex '>
                        <div className="flex flex-col pr-2">
                            <label htmlFor={`tasks-${user.uuid}`} className="text-gray text-sm">
                                Tasks
                            </label>
                            <input
                                id={`tasks-${user.uuid}`}
                                className="border border-gray-300 rounded px-2 py-1 w-12"
                                type="text"
                                value={quotas.tasks || ''}
                                onChange={(e) => handleQuotaChange('tasks', e.target.value)}
                            />
                        </div>
                        {uniqueFields.map((field, index) => {
                            const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
                            const allowedFields = ["budget", "extBudget", "hours"];

                            if (allowedFields.includes(field)) {
                                return (
                                    <div key={index} className="flex flex-col pr-2">
                                        <label htmlFor={`${field}-${user.uuid}`} className='text-gray text-sm'>{capitalizedField}</label>
                                        <input
                                            id={`${field}-${user.uuid}`}
                                            className="border border-gray-300 rounded px-2 py-1 w-12"
                                            type="text"
                                            value={quotas[field] || ''}
                                            onChange={(e) => handleQuotaChange(field, e.target.value)}
                                        />
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                </div>
            }

            <div className="w-full flex items-center justify-end z-10">
                {/* Kebab Menu Button */}
                <button
                    ref={butRef}
                    onClick={toggleMenu}
                    className="text-mine font-black"
                    style={{ borderRadius: '50%', width: '30px', height: '30px' }}
                >
                    &#x22EE;
                </button>
            </div>
            {/* Menu */}
            {showMenu && (
                <div className='justify-end -ml-4'>
                    <div className='absolute w-40 translate-x-1/2' ref={menuRef} >
                        <div className={`z-[${menuZIndex}] flex flex-col bg-white shadow-lg p-2 rounded-xl`}
                            style={{ boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', transform: 'translate(-100%,30%)' }}>
                            <div
                                className='cursor-pointer w-full text-left px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium'
                                onClick={(e) => { toggleMenu(e); handleResetUser(user) }}>
                                Reset
                            </div>
                            <div
                                className='cursor-pointer w-full text-left px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium'
                                onClick={(e) => { toggleMenu(e); handleDisableUser(user) }}>
                                {user.isDisabled ? 'Enable' : 'Disable'}
                            </div>
                            <div
                                className='cursor-pointer w-full text-left px-4 py-2 text-gray-400 hover:bg-mine-10 rounded-lg font-medium'
                                onClick={(e) => { toggleMenu(e); handleDeleteUser(user) }}>
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showUserSelectionPopup && (
                <div ref={popupRef}>
                    <UserSelectionPopup
                        visible={showUserSelectionPopup}
                        cursorPosition={cursorPosition}
                        users={allUsers}
                        selectedUsers={selectedManagers}
                        onUserSelect={handleManagerChange}
                        onClickOut={closePopup}
                        onClickBut={() => setShowUserSelectionPopup(2)}
                        toggleRef={toggleRef}
                    />
                </div>
            )}
        </div>
    );
};

export default UserCard;